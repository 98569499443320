import _, { isEqual, isNil } from 'lodash';
import { BindAll } from 'lodash-decorators';
import React from 'react';
// @ts-ignore
import keydown from 'react-keydown';
import { connect } from 'react-redux';
import { Button, Dropdown, Icon, Segment, DropdownProps } from 'semantic-ui-react';
import DraggableContainer from 'src/components/DraggableContainer/DraggableContainer';
import MinimizableItem from 'src/components/DraggableContainer/MinimizableItem';
import GridErrorBoundary from 'src/components/ErrorBoundary/GridErrorBoundary';
import LoadingMask from 'src/components/LoadingMask/LoadingMask';
import TitledModal from 'src/components/Modal/TitledModal';
import AgPivot, { AgPivot as IAgPivot } from 'src/components/Mfp/Pivot/AgPivot';
import PivotConfigurator from 'src/components/Mfp/PivotConfigurator/PivotConfigurator';
import { ConfigGroup } from 'src/components/Mfp/PivotConfigurator/PivotConfigurator.types';
import {
  PivotOptions,
  getAvailableListing,
  getEffeciveGroups,
  getGroupFromConfigItem,
  getUnFilteredGroupFromConfigItem,
  DimensionItem,
} from 'src/components/Mfp/PivotConfigurator/utils';
import { PivotCell, CELL_TAG_LOCKED, CELL_TAG_FROZEN } from 'src/pivot/PivotCell';
import PivotConfig from 'src/pivot/PivotConfig';
import { configItemToSimpleFavorite, FavoritesEntry } from 'src/services/Favorites';
import './_visualize-summary-pivot.scss';
import { mapDispatchToProps, mapStateToProps } from './visualize-summary-pivot-ag.container';
import { VisualizeSummaryPivotProps, VisualizeSummaryPivotState } from './visualize-summary-pivot-ag.types';
import { API_BASE_URL } from 'src/state/ViewConfig/ViewConfig.slice';
import { isScopeNotReady } from 'src/state/scope/Scope.types';
import { toast } from 'react-toastify';
import { ReportStatus } from 'src/services/Reports.client';
import MacroSummaries from 'src/components/Mfp/MacroSummaries/MacroSummaries';
import { CONTEXT_PENDING, CONTEXT_READY } from 'src/state/workingSets/workingSets.types';
import { getCellStateFromCells } from 'src/pivot/PivotCells.utils';
import { VisualizeContextMenu } from 'src/components/VisualizeContextMenu/VisualizeContextMenu';
import EditableChartHooks from 'src/components/Mfp/EditableChart/EditableChartHooks';
import { AxiosError } from 'axios';
import { withTranslation } from 'react-i18next';
import { makeMfpScopeSensitive } from 'src/components/higherOrder/MfpScopeSensitive';
import serviceContainer from 'src/ServiceContainer';
import MfpSubheader from 'src/components/Mfp/MfpSubheader/MfpSubheader';

@BindAll()
export class VisualizeSummaryPivotAg extends React.Component<VisualizeSummaryPivotProps, VisualizeSummaryPivotState> {
  public constructor(props: VisualizeSummaryPivotProps) {
    super(props);
    this.state = VisualizeSummaryPivotAg.getDerivedStateFromProps(props, {
      viewParams: props.viewParams,
      isSaving: false,
      isConfigModalOpen: false,
      allAvailableListing: [],
      availableGroup: [],
      rowGroup: [],
      colGroup: [],
      contextItems: [],
    });
  }

  public pivot: IAgPivot | undefined;
  public configurator: PivotConfigurator | undefined;

  public static getDerivedStateFromProps(props: VisualizeSummaryPivotProps, state: VisualizeSummaryPivotState) {
    const newState = { ...state };
    const mainConfig = props.mainConfig;
    const settings = props.settings;
    if (!mainConfig || !settings || isScopeNotReady(mainConfig)) {
      return newState;
    }
    newState.viewParams = props.viewParams;

    const maybeAlternateDims = [
      props.viewParams!.rows.find((rd) => rd.hierarchy),
      props.viewParams!.columns.find((cd) => cd.hierarchy),
    ].filter((dd) => !!dd);

    newState.allAvailableListing = getAvailableListing(mainConfig, settings, maybeAlternateDims as DimensionItem[]);

    if (newState.favoriteValue) {
      // Do nothing
    } else if (newState.viewParams) {
      const viewParams = newState.viewParams;

      if (state.pivotConfig) {
        newState.availableGroup = state.availableGroup;
        newState.rowGroup = state.rowGroup;
        newState.colGroup = state.colGroup;
      } else {
        const effective = getEffeciveGroups(newState.allAvailableListing, viewParams);
        newState.availableGroup = effective.available;
        newState.rowGroup = effective.row;
        newState.colGroup = effective.column;
      }
    } else {
      newState.availableGroup = newState.allAvailableListing;
      newState.rowGroup = [];
      newState.colGroup = [];
    }
    return newState;
  }

  public toggleLock() {
    const { isLockToggled, onUnlockAllClick, scopeId } = this.props;
    if (isLockToggled && scopeId) {
      if (onUnlockAllClick) {
        onUnlockAllClick(this, serviceContainer.axios, scopeId);
      }
    }
    this.pivot!.setFocusedCell();
  }

  public onSaveWorkingPlan() {
    const { saveWorkingPlan, scopeId } = this.props;

    if (saveWorkingPlan && scopeId) {
      this.setState({
        isSaving: true,
      });
      saveWorkingPlan(serviceContainer.axios, scopeId).then(() => {
        this.setState({
          isSaving: false,
        });
      });
    }
  }

  /*** KEYBOARD EVENTS ***/
  // undo grid
  @keydown('ctrl+z', 'meta+z')
  public keyUndo(e: React.KeyboardEvent) {
    // check for edit state? can this affect the inputs?
    e.preventDefault();
    e.stopPropagation();
    this.onClickUndo();
    this.pivot!.setFocusedCell();
  }

  // redo grid
  @keydown('ctrl+y', 'meta+y')
  public keyRedo(e: React.KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.onClickRedo();
    this.pivot!.setFocusedCell();
  }

  // lock cell
  @keydown('ctrl+g')
  public keyLock(e: React.KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    const pivot = this.pivot;
    let cells: PivotCell[] = [];

    if (pivot) {
      cells = pivot.getSelectedCells();
    }

    const isUnlockable = cells.filter((cell: PivotCell) => cell.hasTag(CELL_TAG_LOCKED)).length === cells.length;

    if (isUnlockable) {
      this.relaxCells();
    }
    this.lockCells();
  }

  // freeze cell
  @keydown('ctrl+e')
  public keyFreeze(e: React.KeyboardEvent) {
    e.preventDefault();
    e.stopPropagation();

    const pivot = this.pivot;
    let cells: PivotCell[] = [];

    if (pivot) {
      cells = pivot.getSelectedCells();
    }

    const isUnfreezeable = cells.filter((cell: PivotCell) => cell.hasTag(CELL_TAG_FROZEN)).length === cells.length;

    if (isUnfreezeable) {
      this.relaxCells();
    }
    this.freezeCells();
  }

  @keydown('ctrl+d')
  public keyRelax() {
    this.relaxCells();
  }

  public async onClickDownload() {
    const { scopeId, pivotName } = this.props;

    // only one download is allowed at a time
    if (this.state.xlsDownloadStatus?.status === CONTEXT_PENDING) {
      return;
    }
    const tokenValue = serviceContainer.lastBearerToken();
    const requestGenerateReportUrl = `${API_BASE_URL}/context/${scopeId}/pivot/${pivotName}/export?token=${tokenValue}`;
    try {
      await serviceContainer.axios.get<ReportStatus>(requestGenerateReportUrl).then((rep) => {
        this.setState({
          xlsDownloadStatus: {
            id: rep.data.id,
            status: CONTEXT_PENDING,
          },
        });
      });
    } catch (e) {
      const error = e as AxiosError;
      const reportDownloadErrorMessage = 'An error has occued downloading your report';

      const errorPayload =
        error.response && error.stack && typeof error.response === 'object' && typeof error.stack === 'string'
          ? error.stack.concat(JSON.stringify(error.response))
          : error.stack;

      serviceContainer.loggingService.error(reportDownloadErrorMessage, errorPayload);
      toast.error(reportDownloadErrorMessage, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  }

  public onClickUndo() {
    const { scopeId, onClickUndo } = this.props;
    if (onClickUndo && scopeId) {
      onClickUndo(scopeId).then(() => this.pivot!.setFocusedCell());
    }
  }

  public onClickRedo() {
    const { scopeId, onClickRedo } = this.props;
    if (onClickRedo && scopeId) {
      onClickRedo(scopeId).then(() => this.pivot!.setFocusedCell());
    }
  }

  public freezeCells = () => {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }

    cells = pivot.getSelectedCells();

    pivot.state.manager
      .lockCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenu);
        });
      });
  };

  public lockCells = () => {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }
    cells = pivot.getSelectedCells();

    pivot.state.manager
      .constrainCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenu);
        });
      });
  };

  public relaxCells = () => {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];
    if (!pivot) {
      return;
    }
    cells = pivot.getSelectedCells();

    pivot.state.manager
      .relaxCells(cells)
      .then((isGlobalLock) => {
        if (pivot.props.updateLockState) {
          return pivot.props.updateLockState(pivot.props.config.scopeId, isGlobalLock);
        }
        return false;
      })
      .then(() => {
        pivot.refreshCells();
        pivot.setState({ isEditing: false }, () => {
          pivot.gridApi.addEventListener('cellsReturned', this.updateContextMenu);
        });
      });
  };

  public onLayoutManagerOpen() {
    this.setState({ isConfigModalOpen: true });
  }

  public onLayoutManagerClose() {
    this.setState({ isConfigModalOpen: false });
  }

  public onLayoutManagerSubmit() {
    const mainConfig = this.props.mainConfig;
    if (!mainConfig || !this.configurator || isScopeNotReady(mainConfig)) {
      return;
    }

    const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
    const availalbe = this.configurator.state.groups[0].children;
    const rows = this.configurator.state.groups[1].children;
    const columns = this.configurator.state.groups[2].children;
    const rGroups = [];
    const cGroups = [];

    for (const row of rows!) {
      rGroups.push(getUnFilteredGroupFromConfigItem(row, levelsMap));
    }
    for (const col of columns!) {
      cGroups.push(getUnFilteredGroupFromConfigItem(col, levelsMap));
    }

    const pivotConfig = new PivotConfig({
      scopeId: this.props.scopeId,
      rows: rGroups,
      columns: cGroups,
      relocalizeCurrency: this.state.viewParams?.relocalizeCurrency,
    });

    this.setState(
      {
        pivotConfig,
        rowGroup: rows as ConfigGroup[],
        colGroup: columns as ConfigGroup[],
        availableGroup: availalbe as ConfigGroup[],
        isConfigModalOpen: false,
      },
      () => {
        this.updateContextMenu();
      }
    );
  }

  componentDidUpdate(prevProps: VisualizeSummaryPivotProps, prevState: VisualizeSummaryPivotState) {
    if (
      prevProps.gridAsyncState !== this.props.gridAsyncState ||
      !isEqual(prevState.xlsDownloadStatus, this.state.xlsDownloadStatus)
    ) {
      this.updateContextMenu();
    }
    if (this.state.xlsDownloadStatus) {
      try {
        const authToken = serviceContainer.lastBearerToken();
        this.props.reports.forEach((rep) => {
          if (rep.id === this.state.xlsDownloadStatus?.id && rep.status === CONTEXT_READY) {
            this.setState(
              {
                xlsDownloadStatus: undefined,
              },
              () => (window.location.href = `${API_BASE_URL}/drop/${rep.id}?token=${authToken}`)
            );
          }
        });
      } catch (e) {
        toast.error('An error has occued downloading your report');
        serviceContainer.loggingService.error('An error has occued downloading your report');
      }
    }
  }

  public onFavoritesAdd = (_e: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const groupId = this.props.groupId;
    if (!this.configurator) {
      return;
    }
    if (typeof data.value !== 'string') {
      return;
    }

    const rows = this.configurator.state.groups[1];
    const columns = this.configurator.state.groups[2];

    const faveOpts: PivotOptions = {
      rows: rows.children!.map((child) => configItemToSimpleFavorite(child, this.configurator!)),
      columns: columns.children!.map((child) => configItemToSimpleFavorite(child, this.configurator!)),
    };

    if (this.props.addToFavorites) {
      const fe: FavoritesEntry = {
        key: data.value,
        group: groupId,
        value: faveOpts,
      };
      this.props.addToFavorites(serviceContainer.axios, fe);
    }
    this.setState({ favoriteValue: data.value });
  };

  public onFavoritesChange(newValue: string) {
    if (!this.configurator) {
      return;
    }
    const keys = _.keyBy(this.props.favorites, 'key');
    const faveEntry: FavoritesEntry = keys[newValue];

    if (!faveEntry) {
      return;
    }

    const effective = getEffeciveGroups(this.state.allAvailableListing, faveEntry.value);

    this.setState({
      favoriteValue: newValue,
      rowGroup: effective.row,
      colGroup: effective.column,
      availableGroup: effective.available,
    });

    this.configurator.setState({
      groups: [
        {
          id: 'available',
          name: 'Dimension',
          children: _.cloneDeep(effective.available) || [],
        },
        {
          id: 'rows',
          name: 'Rows',
          children: _.cloneDeep(effective.row) || [],
        },
        {
          id: 'columns',
          name: 'Columns',
          children: _.cloneDeep(effective.column) || [],
        },
      ],
    });
  }

  public onPivotChange() {
    this.updateContextMenu();
  }

  public handleSetPivot = (pivot: IAgPivot) => {
    this.pivot = pivot;
  };

  public updateContextMenu() {
    const pivot = this.pivot;
    let cells: PivotCell[] = [];

    if (pivot) {
      cells = pivot.getSelectedCells();
    }

    const cellState = getCellStateFromCells(cells);

    const contextItems = (
      <VisualizeContextMenu
        hasEditableRevision={this.props.hasEditableRevision}
        cellState={cellState}
        favoritesLoading={this.state.xlsDownloadStatus?.status === CONTEXT_PENDING}
        gridAsyncState={this.props.gridAsyncState}
        availableGroup={this.state.availableGroup}
        handleClickUndo={this.onClickUndo}
        handleClickRedo={this.onClickRedo}
        handleClickLayoutManagerOpen={this.onLayoutManagerOpen}
        handleClickDownload={this.onClickDownload}
        freezeCells={this.freezeCells}
        lockCells={this.lockCells}
        relaxCells={this.relaxCells}
      />
    );

    this.setState({ contextItems });
  }

  handleDimensionChange = () => {
    if (isNil(this.props.onforceRefreshGrid)) {
      return;
    }

    this.props.onforceRefreshGrid();
  };

  public render() {
    const mainConfig = this.props.mainConfig;
    const { t } = this.props;
    if (isScopeNotReady(mainConfig) || !this.props.scopeReady) {
      return (
        <Segment className="visualize-summary-pivot-uninitialized">
          <Icon className="far fa-exclamation-circle" />
          Scope not ready, please wait while your scope is prepared
        </Segment>
      );
    }
    if (!mainConfig) {
      return <React.Fragment />;
    }
    if (!this.props.initialized && !this.props.isFetchingScope && this.props.scopeReady) {
      return (
        <Segment className="visualize-summary-pivot-uninitialized">
          <Icon className="far fa-exclamation-circle" />
          Plan not initialized. Please Initialize the Plan
        </Segment>
      );
    }

    const levelsMap = _.keyBy(_.flatMap(mainConfig.levels), 'id');
    const viewParams = this.state.viewParams;
    if (!viewParams) {
      return <React.Fragment />;
    }

    const rowDimensions = viewParams.rows.map((entry) => entry.dimension);
    const colDimensions = viewParams.columns.map((entry) => entry.dimension);
    const dimRowKeyToIndex = _.mapValues(
      _.keyBy(
        _.map(rowDimensions, (d, i) => ({ d, i })),
        'd'
      ),
      'i'
    );
    const dimColKeyToIndex = _.mapValues(
      _.keyBy(
        _.map(colDimensions, (d, i) => ({ d, i })),
        'd'
      ),
      'i'
    );

    let rowMemberInfo: ConfigGroup[] = this.state.rowGroup;
    let colMemberInfo: ConfigGroup[] = this.state.colGroup;

    rowMemberInfo = _.sortBy(rowMemberInfo, (g) => dimRowKeyToIndex[g.id]);
    colMemberInfo = _.sortBy(colMemberInfo, (g) => dimColKeyToIndex[g.id]);

    const pivotRows = rowMemberInfo.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));
    const pivotCols = colMemberInfo.map((mi) => getGroupFromConfigItem(mi, viewParams, levelsMap));

    const pivotConfig = new PivotConfig({
      scopeId: this.props.scopeId,
      rows: pivotRows,
      columns: pivotCols,
      relocalizeCurrency: viewParams.relocalizeCurrency,
    });

    const keys = Object.keys(_.keyBy(this.props.favorites, 'key'));
    const faveOptions = keys.map((key) => ({ value: key, text: key }));

    return (
      <div className="visualize-summary-pivot">
        <MfpSubheader
          title={viewParams.title || ''}
          lockToggleParams={{
            active: this.props.isLockToggled,
            onClick: this.toggleLock,
            hidden: !this.props.hasEditableRevision,
          }}
        />

        <DraggableContainer>
          {this.props.viewParams?.editableChart ? (
            <EditableChartHooks viewParams={this.props.viewParams.editableChart} />
          ) : (
            <MacroSummaries />
          )}
          <MinimizableItem title="" optionChildren={this.state.contextItems}>
            <GridErrorBoundary errorTitle={'Something has gone wrong with the grid'}>
              <AgPivot
                config={this.state.pivotConfig || pivotConfig}
                onDataChange={this.onPivotChange}
                onSelectionChange={this.onPivotChange}
                onMount={this.onPivotChange}
                handleDownloadXLS={this.onClickDownload}
                handleSetPivot={this.handleSetPivot}
              />
            </GridErrorBoundary>
          </MinimizableItem>
        </DraggableContainer>
        <TitledModal title="Layout Manager" show={this.state.isConfigModalOpen} closeModal={this.onLayoutManagerClose}>
          <div className={'layout-modal-body'}>
            <PivotConfigurator
              // @ts-ignore
              ref={(el) => (this.configurator = el)}
              available={this.state.availableGroup}
              rows={this.state.rowGroup}
              columns={this.state.colGroup}
              settings={this.props.settings}
            />
          </div>
          <div className={'layout-footer'}>
            <div className="favorite-title">Save as {t('favorite', { postProcess: 'capitalize' })}</div>
            <Dropdown
              search={true}
              selection={true}
              allowAdditions={true}
              placeholder={`<Type ${t('favorite', { postProcess: 'capitalize' })} name>`}
              options={faveOptions}
              onAddItem={this.onFavoritesAdd}
              value={this.state.favoriteValue}
              onChange={(__, data) => this.onFavoritesChange(data.value as string)}
            />
            <Button content={'CANCEL'} onClick={this.onLayoutManagerClose} />
            <Button content={'SUBMIT'} className="layout-submit-modal-button" onClick={this.onLayoutManagerSubmit} />
          </div>
        </TitledModal>
        {this.state.isSaving && <LoadingMask />}
      </div>
    );
  }
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(makeMfpScopeSensitive(withTranslation(null, { withRef: true })(VisualizeSummaryPivotAg)));
