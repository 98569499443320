import {
  ValueFormatterParams,
  SuppressKeyboardEventParams,
  ValueSetterParams,
  ValueGetterParams,
  CellClassParams,
  CellRendererSelectorResult,
  CellEditorSelectorResult,
  CellStyle,
  EditableCallbackParams,
  ITooltipParams,
} from '@ag-grid-community/core';
import {
  ColGroupDef,
  ColDef,
  CellClickedEvent,
  RowSelectedEvent,
  GridApi,
  GridReadyEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColumnApi,
  CellValueChangedEvent,
  ICellRendererParams,
  ICellEditorParams,
  _,
  IRowNode,
  GetGroupRowAggParams,
} from '@ag-grid-community/core';

import ExtendedDataGrid from 'src/components/ExtendedDataGrid/ExtendedDataGrid';
import { DataGridProps, ScrollTo } from 'src/common-ui/components/DataGrid/DataGrid';
import {
  mapValues,
  filter,
  findIndex,
  isNil,
  isEmpty,
  has,
  hasIn,
  flow,
  forEach,
  get,
  isEqual,
  concat,
  omitBy,
  isBoolean,
  every,
  some,
  flatten,
  reduce,
  debounce,
  find,
  isObject,
  difference,
  pickBy,
  set,
  keyBy,
  isString,
} from 'lodash';
import React from 'react';

import { Overlay } from 'src/common-ui/index';
import {
  STYLE_ID,
  STYLE_COLOR_ID,
  LOCKED_AFTER_STYLE_SUBMIT,
  LOCKED_AFTER_COLOR_SUBMIT,
  STYLE_SUBMITTED_ATTR,
  COLOR_SUBMITTED_ATTR,
  ATTR_GRADE,
  ATTR_CLIMATE,
  ATTR_MENSCAPACITY,
  ATTR_WOMENSCAPACITY,
  ATTR_SSG,
  ATTR_FUNDED,
  USERADJ,
  ONORDERREVISION,
  SLSUOVERRIDE,
  POPOVER_BLOCK_CODES,
  BLOCK_ENTER_EDITORS,
  STORE_COUNT,
  EDITORS_TO_IGNORE_CHANGE_DETECTION,
  ARRAY_EDITOR_COLUMN_ID_PREFIX,
} from 'src/utils/Domain/Constants';
import {
  gridListPairStyle,
  gridContainerStyle,
  editableCell,
  headerCheckbox,
  wrappedHeaderStyle,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.styles';
import {
  ConfigurableGridConfigItem,
  MassColumnUpdateParams,
  AsyncCellState,
  ConfigurableGridState,
  ConfigurableGridOwnProps,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { ParamedCalc, importDateFunctions } from 'src/utils/LibraryUtils/MathUtils';
import Axios from 'src/services/axios';
import { map, reduce as reduceFP, isNumber, partial, isArray } from 'lodash/fp';
import { processApiParams, getUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { executeCalculation } from 'src/utils/LibraryUtils/MathUtils';
import { GroupHeaderKey } from 'src/utils/Component/AgGrid/AgDataFormat';
import ServiceContainer from 'src/ServiceContainer';
import { style } from 'typestyle';
import Renderer, { PERCENT_RENDERERS } from 'src/utils/Domain/Renderer';
import coalesce from 'src/utils/Functions/Coalesce';
import {
  PENDING_VALIDATION_VALUE,
  PendingCellInfo,
  viewDefnWhitelistToNarrowedCharacterWhitelist,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationEditor';
import { parseObservers, ObservableGridProps, isObservedProp } from 'src/utils/Component/ObservervableGridProps';
import { updateStyleItem, getDependentsFromResp } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import {
  getValidValues,
  updateLifecycleParams,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/StyleEditSection.client';
import {
  calculateColumnWidth,
  updateWithClientHandler,
  replaceExtraProps,
  resetAsyncValidationData,
  refreshGridCells,
  getCellAsyncState,
  updateNodeAsyncState,
  isGroupNode,
  updateAsyncState,
  isGroupNodeUpdate,
  generateCoordinateValues,
  filterItemForRedecorate,
  isNotGroupNode,
} from 'src/components/ConfigurableGrid/utils/ConfigurableGrid.utils';
import { toast } from 'react-toastify';
import moment from 'moment';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { BasicItem as PivotBasicItem } from 'src/worker/pivotWorker.types';
import { logError, logWarn } from 'src/services/loggingService';
import { EditCoordinates, GranularEditPayloadItem } from 'src/dao/pivotClient';
import { multiHeaderDecorate } from 'src/common-ui/components/DataGrid/NestedHeader';
import { zConfigurableGridGroupEditors } from 'src/services/configuration/codecs/viewdefns/literals';
import { frameworkComponents, nonFrameworkComponents, AsyncValidationErrorMessage } from './EditableGrid.subcomponents';
import { CheckboxHeaderRendererProps } from 'src/components/CheckboxHeaderRenderer/CheckboxHeaderRenderer';
import { ValidValuesCheckBoxEditorHeaderProps } from 'src/components/ValidValuesCheckboxEditor/ValidValuesCheckboxEditorHeader';
import type { ClientDataApi, DataApi } from 'src/services/configuration/codecs/confdefnView';

import { getMergedRangeLists } from 'src/dao/scopeClient';
import { StyleDetailsPopoverProps } from 'src/components/AssortmentStyleDetailsPopover/AssortmentStyleDetailsPopover';
import { ConfigurableGridValueProps } from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { getGridRowHeight } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheet.utils';
import { TEMP_REC_ADJ_CONFIG_API, getRecAdjDataApi } from 'src/pages/AssortmentBuild/FlowSheet/FlowSheetGrid';

import * as globalMath from 'mathjs';
import { all } from 'mathjs';
import { MIN_COL_WIDTH } from 'src/components/ListGridPair/ListGridPair.styles';
import { TextValidationHeaderEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/TextValidationHeaderEditor';
import { MassEditCoordinateMap, NoRowsOverlayConfig } from 'src/services/configuration/codecs/viewdefns/general';
import { ValidValuesHeaderEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidValuesHeaderEditor';
import CheckboxHeaderEditor from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/CheckboxHeaderEditor';
import { ValidSizesHeaderEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/ValidSizesHeaderEditor';
import { IntegerHeaderEditor } from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSection/Editors/IntegerHeaderEditor';
import { Suggestion } from 'src/common-ui/components/Inputs/InputSuggest/InputSuggest';
import { GridNoRowsOverlay } from 'src/components/NoRowsOverlay/NoRowsOverlay';
import { WithBreadCrumbProps } from 'src/components/higherOrder/withBreadcrumb';
import { extractNavPathFromString } from 'src/pages/NavigationShell/navigationUtils';
import { isSome } from 'fp-ts/es6/Option';

type PostValue = any;

// TODO: change all the drilled properties to use pick<>?
export interface EditableGridProps
  extends Omit<StyleDetailsPopoverProps, 'onItemClicked'>,
    Pick<
      ConfigurableGridValueProps,
      | 'leafIdProp'
      | 'columnDefs'
      | 'dataLoaded'
      | 'data'
      | 'groupBySelection'
      | 'configureOptions'
      | 'configureSelections'
      | 'dependentCalcs'
      | 'massEditConfig'
      | 'groupByDropdownProps'
      | 'floorsetDropdownProps'
      | 'adornments'
      | 'salesAdjustmentConfig'
      | 'showPublish'
      | 'clientActionHandlers'
      | 'enableCheckboxSelection'
      | 'updateCoordinateMap'
      | 'onlySendCoordinateMap'
    >,
    Pick<ConfigurableGridState, 'validValuesCache' | 'activeStyleColor'>,
    Pick<ConfigurableGridOwnProps, 'topMembers' | 'topMemberObj'>,
    WithBreadCrumbProps {
  dataApi: DataApi;
  configLoaded: boolean;
  redecorateMap?: Record<string, string>;
  activeFloorset: string;
  handleGridReady: (params: GridReadyEvent) => void;
  gridRowHeight?: number;
  groupRowHeight?: number;
  gridScrollTo?: ScrollTo;
  onRowSelected?(event: RowSelectedEvent): void;
  onCellClicked: (event: CellClickedEvent) => void;
  onItemClicked: (item: BasicPivotItem | BasicPivotItem[]) => void;
  onPopoverItemClicked: (item: BasicPivotItem) => void;
  isRowSelectable: (row: IRowNode) => boolean;
  syncGridData(redecoratedData: BasicPivotItem[]): void;
  noRowsOverlayConfig: NoRowsOverlayConfig;
}

interface EditableGridState {
  /** used to signal to MassEdit that the grid is ready */
  groupByIndexTemp?: number;
  massEditGridProcessing: boolean;
  selectedItems: IRowNode[];
  /** keeps track of check type to handle logic for retrieving toggled items properly when grouped */
  previousSelectionType: 'single' | 'group' | null;
  arrayEditorBaseValues: Record<string, any>;
  columnDefs: (ColDef<any, any> | ColGroupDef<any>)[];
}
export class EditableGrid extends React.Component<EditableGridProps, EditableGridState> {
  gridApi!: GridApi;
  columnApi!: ColumnApi;
  nonFrameworkComponents = nonFrameworkComponents;
  frameworkComponents = frameworkComponents;
  observers: ObservableGridProps = {};
  allowEnterList: (string | undefined)[] = [];
  math = globalMath.create(all) as globalMath.MathJsStatic;
  logger = ServiceContainer.loggingService;
  arrayEditPromise: Promise<void> | null = null;
  /**
   * This object stores the ordered values any valid values checkbox editor headers (i.e. store vol. tier)
   */
  vvHeaderMap: Record<string, string[]> = {};

  constructor(props: EditableGridProps) {
    super(props);
    this.state = {
      massEditGridProcessing: false,
      selectedItems: [],
      previousSelectionType: null,
      arrayEditorBaseValues: {},
      columnDefs: [],
    };

    const aggregateColumn = (
      args: globalMath.MathNode[],
      _mathjs: globalMath.MathJsStatic,
      scope: { [s: string]: unknown } | Map<string, unknown>
    ) => {
      const expressionField = args.map((arg) => (arg.name ? arg.name : ''));

      if (expressionField.length < 1 || isNil(this.gridApi)) {
        return 0;
      }

      const field = expressionField[0];
      const colDef = this.props.columnDefs.find((col) => col.dataIndex === field);
      let aggType = get(colDef, 'aggregator', 'sum');
      if (aggType === 'eval') {
        console.warn("We don't currently support eval aggs in other aggs. Falling back to sum."); // eslint-disable-line no-console
        aggType = 'sum';
      }
      const value = scope instanceof Map ? scope.get(field) : scope[field];
      const fieldAggregation = this.math[aggType](value);

      return fieldAggregation;
    };

    // register AGG method with mathjs
    (aggregateColumn as any).rawArgs = true;
    this.math.import({ AGG: aggregateColumn }, { override: true });
  }

  async componentDidMount() {
    // setup math functions with date math handlers
    const mergedRangeList = await getMergedRangeLists();
    try {
      importDateFunctions(this.math, mergedRangeList);
      this.fetchArrayColumns();
      this.refreshColumnDefs();
    } catch (error) {
      toast.error(`An errors occur when trying to import date`);
      ServiceContainer.loggingService.error(`An errors occur when trying to import date`);
    }
  }

  refreshColumnDefs() {
    // generate the group by
    const generatedColumnDefs = this.generateColumnDefs();
    // ideally multiHeaderDecorate would actually refire inside DataGrid
    const updatedColumnDefs = multiHeaderDecorate(generatedColumnDefs);
    // reset state on groupBy change
    this.setState({
      previousSelectionType: null,
      columnDefs: updatedColumnDefs,
    });
  }

  componentDidUpdate(prevProps: EditableGridProps) {
    const groupByChanged =
      !isEqual(this.props.groupBySelection, prevProps.groupBySelection) ||
      !isEqual(this.props.configureSelections, prevProps.configureSelections);

    const colDefsReady = !isEqual(this.props.columnDefs, prevProps.columnDefs);
    if (colDefsReady || groupByChanged) {
      this.refreshColumnDefs();
    }
    if (!isEqual(prevProps.topMembers, this.props.topMembers)) {
      this.fetchArrayColumns();
    }
  }

  fetchArrayColumns() {
    const hasArrayEditorColumns = this.props.columnDefs.some((c) => c.inputParams?.useArrayEdit);
    if (hasArrayEditorColumns) {
      // array edit columns need to have their element sets fetched at load time, so we know the possible set of options to display
      const maybeArrayEditColumns = this.props.columnDefs.filter((c) => c.inputParams?.useArrayEdit);
      const maybeArrayDataApis = maybeArrayEditColumns.map((c) => {
        // this is somewhat magical, in that the config needs to be carefully aligned with the api endpoint that is being fetched in this instance.
        // for example, `validValues` uses `members` as the "top member" property,
        // but we are inconsistent across endpoints with what that property is called,
        // so the configured `dataApi.params` has to be aligned with the endpoints expected "top members" property,
        // such as `parentId` or whatever is expected
        const tMO = this.props.topMemberObj ?? {};
        const processedDataApi = processApiParams(c.dataApi, {
          topMember: this.props.topMembers,
          ...tMO,
        });
        const dataUrl = getUrl(processedDataApi);
        return getValidValues(dataUrl, false);
      });
      Promise.all(maybeArrayDataApis).then((validValues) => {
        const rets = validValues.map((validValue, indx) => {
          return {
            values: validValue,
            dataIndex: maybeArrayEditColumns[indx].dataIndex,
          };
        });
        const arrayEditColumnsMap = keyBy(rets, 'dataIndex');
        this.setState(
          {
            arrayEditorBaseValues: arrayEditColumnsMap,
          },
          () => this.refreshColumnDefs()
        );
      });
    }
  }

  handlePendingCellUpdate(value: string, pendingCellInfo: PendingCellInfo) {
    if (!isNil(pendingCellInfo.validation) && !pendingCellInfo.validation.isValid) {
      const { invalidValue, initialValue } = pendingCellInfo.validation;
      const initial = isNil(initialValue) || isEmpty(initialValue) ? 'Empty' : initialValue;
      const message = <AsyncValidationErrorMessage initial={`"${initial}"`} invalidValue={`"${invalidValue}"`} />;
      toast.error(message, {
        autoClose: false,
        position: toast.POSITION.TOP_LEFT,
      });
    }

    let rowNode: IRowNode | null = null;
    let updatedCellIndex = -1;

    this.gridApi.forEachNodeAfterFilter((node, index) => {
      if (node.id === pendingCellInfo.id) {
        rowNode = node;
        rowNode.data[pendingCellInfo.dataIndex] = value;
        updatedCellIndex = index;
      }
    });

    // reset grid scroll only after validation and data is updated or reset
    let gridScrollTo = this.props.gridScrollTo;
    if (value !== PENDING_VALIDATION_VALUE) {
      gridScrollTo = {
        eventId: Date.now(),
        where: {
          key: !isNil(gridScrollTo) ? gridScrollTo.where.key : `member:${this.props.leafIdProp}:name`,
          value,
        },
      };
      const indexSplit = pendingCellInfo.dataIndex.split(':');
      if (!isNil(rowNode) && updatedCellIndex !== -1 && indexSplit.length === 3 && indexSplit[1] === 'style') {
        updateStyleItem({
          id: (rowNode as IRowNode).data[this.props.leafIdProp] || '',
          [indexSplit[2]]: value,
        });
      }
    }
  }

  checkAllBoxes = (dataIndex: string, checked: boolean) => {
    const itemsToUpdate: BasicPivotItem[] = [];

    // update row node data without triggering cellValueChanged handler
    this.gridApi.forEachNodeAfterFilter((rowNode) => {
      if (!isNil(rowNode.allChildrenCount) && rowNode.allChildrenCount > 0) {
        // skip nodes that are group nodes.
        return;
      }
      rowNode.data[dataIndex] = checked;
      itemsToUpdate.push(rowNode.data);
    });

    // run ag-grid transaction, then post changes via mass edit api wrapper
    this.gridApi.applyTransaction({ update: itemsToUpdate });

    const updateParams: MassColumnUpdateParams = {
      dataIndex,
      nodes: itemsToUpdate,
      // the values are stored in the grid data as strings
      value: checked ? 'true' : '',
    };

    this.setState(
      {
        massEditGridProcessing: true,
      },
      () => {
        this.submitMassColumnUpdate(updateParams).then(() => {
          this.setState({
            massEditGridProcessing: false,
          });
        });
      }
    );
  };

  // FIXME: see EAS-607
  getRowProcessedApi = (row: IRowNode, configuredApi: ClientDataApi): ClientDataApi | undefined => {
    return !isNil(configuredApi) ? processApiParams(configuredApi, row) : undefined;
  };

  enableFloatingFiltersRow = () => this.props.columnDefs.some((cd) => cd.useColumnHeaderMassEditUpdate === true);

  // Aggrid doesn't have a defined type for `floatingFilterComponentParams` (it's `any`)
  // since anything can be sent into the custom component, so we just return it here
  colInfoToFloatingFilterOptions = (
    colInfo: ConfigurableGridConfigItem,
    cellEditable: (params: { data: unknown; node: IRowNode }) => boolean
  ) => {
    const defaultFloatingFilters = {
      floatingFilterComponent: () => (colInfo.hidden ? null : <div />),
      floatingFilterComponentParams: undefined,
    };

    if (isNil(colInfo.useColumnHeaderMassEditUpdate)) {
      return defaultFloatingFilters;
    }

    const getSelectedItems = () =>
      this.state.selectedItems.filter(isNotGroupNode).filter((item) => {
        return cellEditable({
          node: item,
          data: item.data,
        });
      });
    const getCellsUpdating = () => {
      return this.state.selectedItems.filter(isNotGroupNode).filter((item) => {
        const isAsyncCellEditing = item && getCellAsyncState(item, colInfo);
        const cellInProg = [AsyncCellState.Processing, AsyncCellState.Redecorating].includes(isAsyncCellEditing);
        return cellInProg;
      });
    };
    switch (colInfo.inputType) {
      case 'integer': {
        const percent = colInfo.renderer && PERCENT_RENDERERS.indexOf(colInfo.renderer) > -1;
        // TODO: do I need to format it in the array if column is setup with `useArrayEdit`?
        const handleApplyEdit = colInfo.inputParams?.useArrayEdit ? this.submitGranularUpdate : this.submitCoarseUpdate;
        return {
          floatingFilterComponent: IntegerHeaderEditor,
          floatingFilterComponentParams: {
            getSelectedItems,
            getCellsUpdating,
            onApplyEdit: handleApplyEdit,
            passedInt: null,
            ...colInfo.inputParams,
            percent,
          },
        };
      }
      case 'textValidator':
      case 'textValidatorAsync':
        return {
          floatingFilterComponent: TextValidationHeaderEditor,
          floatingFilterComponentParams: {
            getSelectedItems,
            getCellsUpdating,
            onApplyEdit: this.submitCoarseUpdate,
            editorParams: {
              validateAsync: false,
              invalidDataIndex: colInfo.invalidDataIndex,
              whitelist: viewDefnWhitelistToNarrowedCharacterWhitelist(colInfo.inputParams?.whitelist || ''),
              onValidated: this.handlePendingCellUpdate.bind(this), // will be invoked in promise context, so need to set context
            },
          },
        };
      case 'validSizes':
        return {
          floatingFilterComponent: ValidSizesHeaderEditor,
          floatingFilterComponentParams: {
            getSelectedItems,
            getCellsUpdating,
            onApplyEdit: this.submitCoarseUpdate,
            dataConfig: undefined,
            getDataConfig: this.getRowProcessedApi,
            unprocessedDataConfig: colInfo.dataApi,
          },
        };
      case 'validValues':
      case 'validValuesMulti': {
        const multiSelect = colInfo.inputType === 'validValuesMulti' ? true : undefined;
        const dataQa = isNil(multiSelect) ? 'select-configurable-grid' : 'select-multi-configurable-grid';
        const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
        // only return full object on member updates
        const returnSelectionObject = colInfo.dataIndex.match(/member:([a-z]*):[a-z]*/);

        return {
          floatingFilterComponent: ValidValuesHeaderEditor,
          floatingFilterComponentParams: {
            allowEmptyOption,
            asCsv: colInfo.asCsv,
            concatOptionValues: colInfo.concatOptionValues,
            // Initially has to be undefined when generating params since
            // selected rows are required to generate the dataConfig.
            // The getDataConfig fcn will retrieving values when selections are made.
            dataConfig: undefined,
            dataQa,
            getDataConfig: this.getRowProcessedApi,
            ignoreCache: colInfo.ignoreCache,
            includeCurrent: colInfo.includeCurrent,
            multiSelect,
            onApplyEdit: this.submitCoarseUpdate,
            postArrayAsString: colInfo.postArrayAsString,
            returnSelectionObject,
            getSelectedItems,
            getCellsUpdating,
            unprocessedDataConfig: colInfo.configApi || colInfo.dataApi,
          },
        };
      }
      case 'checkbox': {
        const availableSelections = colInfo.options ? colInfo.options.map((o) => o.value) : [];
        return {
          floatingFilterComponent: CheckboxHeaderEditor,
          floatingFilterComponentParams: {
            isEditable: true,
            availableSelections,
            groupCheckbox: !isEmpty(availableSelections),
            optionsApi: colInfo.dataApi,
            validValuesCache: this.props.validValuesCache,
            getSelectedItems,
            getCellsUpdating,
            onApplyEdit: this.submitCoarseUpdate,
            columnField: colInfo.dataIndex,
          },
        };
      }
      default:
        return defaultFloatingFilters;
    }
  };

  // #region Editable Calculation Fields
  // Tracks edits to cells with calculations per row by setting a special property on each row on edit.
  updateCalcEdit({ node, value, colDef }: { node: IRowNode; value: unknown; colDef: ColDef }) {
    set(node.data, `__calc_editted["${colDef.field}"]`, {
      value,
      editted: true,
    });
  }
  // Fetches the tracked edit for cells with calculations
  // Use this any time you want to handle the result of edit to any cell with `calculation.
  getCalcEdit({ node, colDef, curValue }: { node: IRowNode; colDef: ColDef; curValue: unknown }) {
    const calcEdit = get(node.data, `__calc_editted["${colDef.field}"]`);
    if (calcEdit?.editted) {
      return calcEdit?.value;
    } else {
      return curValue;
    }
  }
  // This should run at the end of each update loop to ensure row status flags cleared.
  clearCalcEditTrackers(nodes: IRowNode[]) {
    nodes.forEach((node) => set(node, `__calc_editted`, {}));
  }
  // #endregion

  createColumnDef = (colInfo: ConfigurableGridConfigItem, enableFloatingFilterRow: boolean): ColDef => {
    // setup observers if applicable
    this.observers = parseObservers(this.observers, colInfo);
    // TODO: fix this
    const floorset = this.props.activeFloorset;
    const tealBackgroundStyle = style({
      backgroundColor: 'rgba(220, 243, 241, .7)',
    });
    const getDataFn = (params: { data: unknown; node: IRowNode | null | undefined }) => {
      return (key: string) => {
        const returnObj = {
          rowNodeFound: false,
          data: undefined as unknown,
        };
        returnObj.rowNodeFound = true;

        returnObj.data = params.node
          ? coalesce(
              this.gridApi.getValue(key, params.node),
              get(params.data, key),
              get(params.data, 'attribute:' + key + ':id')
            )
          : null;
        if (returnObj.data == null) returnObj.data = null;
        return returnObj;
      };
    };
    const isEditable = (params: { data: unknown; node: IRowNode }) => {
      let editable = !!colInfo.editable;
      const allowNonNumber = typeof colInfo.editableByCalc === 'object' ? colInfo.editableByCalc.allowNonNumber : false;

      if (colInfo.editableByCalc != null) {
        editable = !!executeCalculation(this.math, colInfo.editableByCalc, getDataFn(params), allowNonNumber);
      }

      if (LOCKED_AFTER_STYLE_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
        const styleValue =
          params.node &&
          coalesce(this.gridApi.getValue(STYLE_SUBMITTED_ATTR, params.node), get(params.data, STYLE_SUBMITTED_ATTR));
        const styleSubmitted = !isNil(styleValue);
        return colInfo.editable && !styleSubmitted;
      } else if (LOCKED_AFTER_COLOR_SUBMIT.indexOf(colInfo.dataIndex) >= 0) {
        const colorValue =
          params.node &&
          coalesce(this.gridApi.getValue(COLOR_SUBMITTED_ATTR, params.node), get(params.data, COLOR_SUBMITTED_ATTR));
        const colorSubmitted = !isNil(colorValue) && colorValue !== 'Undefined';
        return colInfo.editable && !colorSubmitted;
      }

      if (params.node && isGroupNode(params.node)) {
        const noEditorConfigured = isNil(colInfo.inputType);
        const cascadeGroupSelection = !isNil(colInfo.cascadeGroupSelection);
        const isValidGroupEditor = zConfigurableGridGroupEditors.safeParse(colInfo.inputType).success;
        const isGroupEditable = editable && cascadeGroupSelection && (noEditorConfigured || isValidGroupEditor);
        return isGroupEditable;
      }

      return editable;
    };

    const calculatedWidth = colInfo.width || calculateColumnWidth(colInfo.dataIndex);
    let headerInfo: {
      component?: string;
      params?: ValidValuesCheckBoxEditorHeaderProps | CheckboxHeaderRendererProps;
    } = {
      component: undefined,
      params: undefined,
    };
    // FIXME: Hidden as it fails with redecorate. Make it work with redecorate
    /*if (colInfo.inputType === 'checkbox') {
      headerInfo = {
        component: 'gridHeaderCheckbox',
        params: {
          onChange: this.checkAllBoxes.bind(this, colInfo.dataIndex),
          checkedStatus: 'indeterminate',
          isProcessing: this.state.massEditGridProcessing,
        },
      };
    } else */ if (colInfo.renderer === 'validValuesCheckbox') {
      const availableHeaders = colInfo.options ? colInfo.options.map((o) => o.text) : [];
      headerInfo = {
        component: 'validValuesCheckboxHeader',
        params: {
          availableHeaders,
          optionsApi: colInfo.dataApi,
          validValuesCache: this.props.validValuesCache,
          onHeadersFetched: (headers: string[]) => {
            this.vvHeaderMap[colInfo.dataIndex] = headers;
          },
        },
      };
    }
    const { floatingFilterComponent, floatingFilterComponentParams } = this.colInfoToFloatingFilterOptions(
      colInfo,
      isEditable
    );

    const shouldHideColumn = !isNil(colInfo.visible)
      ? !colInfo.visible
      : !isNil(colInfo.hidden)
        ? colInfo.hidden
        : false;

    return {
      width: calculatedWidth,
      headerName: colInfo.text,
      headerComponent: headerInfo.component,
      headerComponentParams: headerInfo.params,
      headerClass: colInfo.wrapHeaderText ? wrappedHeaderStyle : undefined,
      floatingFilter: enableFloatingFilterRow,
      floatingFilterComponent,
      floatingFilterComponentParams,
      suppressFloatingFilterButton: true,
      pinned: colInfo.pinned,
      colId: colInfo.dataIndex,
      // TODO: for cellRendererSelector to be triggered on a column we have to set the renderer to something other than undefined
      // Probably want to control this default with a known value (zod validator/literals?)
      // relates to INT-2721 and renderering asynccellstate icons during processing
      renderer: colInfo.renderer || 'unknown',
      field: colInfo.dataIndex,
      filterType: colInfo.filterType,
      hide: shouldHideColumn,
      suppressColumnsToolPanel: isNil(colInfo.hidden) ? false : true,
      minWidth: colInfo.renderer === 'backgroundFill' ? MIN_COL_WIDTH : undefined,
      refData: {
        ...colInfo.refData,
        renderer: colInfo.renderer || 'unknown',
        inputType: colInfo.inputType,
        filterType: colInfo.filterType || 'unknown',
        // @ts-ignore
        calculation: colInfo.calculation || 'unknown',
        // @ts-ignore
        inputParams: colInfo.inputParams,
      },
      cellStyle: (params: CellClassParams) => {
        if (colInfo.renderer === 'backgroundFill') {
          return {
            'background-color': params.value,
            color: 'transparent',
            padding: 0,
          } as CellStyle;
        }
        if (!isNil(colInfo.invalidDataIndex) && get(params.data, colInfo.invalidDataIndex) === true) {
          return { border: '1px solid #ff0000' } as CellStyle;
        }
        if (colInfo.wrapText) {
          return { 'white-space': 'normal', 'line-height': 'unset' } as CellStyle;
        }
      },
      cellClass: colInfo.cellClass || colInfo.classes?.join(' '),
      cellClassRules: {
        [editableCell]: (params: CellClassParams): boolean => {
          // popover is no longer configured to be editable, so need this to style icon correctly
          const editable = colInfo.dataIndex === 'popoverTrigger' || isEditable(params);
          return editable && params.node && !params.node.aggData ? true : false;
        },
        [tealBackgroundStyle]: (params: CellClassParams): boolean => {
          return !!colInfo.highlightColumn && params.node && !params.node.aggData ? true : false;
        },
        loading: (params: CellClassParams): boolean => {
          const cellColState = params.node && getCellAsyncState(params.node, colInfo);
          return cellColState === AsyncCellState.Processing;
        },
        redecorating: (params: CellClassParams): boolean => {
          const cellColState = params.node && getCellAsyncState(params.node, colInfo);
          return cellColState === AsyncCellState.Redecorating;
        },
      },
      rowGroup: colInfo.rowGroup ? colInfo.rowGroup : false,
      // Add tooltip support based on the dataIndex for tooltips
      tooltipValueGetter: (params: ITooltipParams) => {
        const tooltipField = colInfo.tooltipDataIndex;
        if (tooltipField) {
          // Use _.coalesce to get the tooltip value
          return coalesce(params.api?.getValue(tooltipField, params.data), get(params.data, tooltipField), null);
        }
        return null;
      },
      tooltipShowDelay: 0,
      tooltipHideDelay: 100,
      editable: (params: EditableCallbackParams) => {
        const isAsyncCellEditing = params.node && getCellAsyncState(params.node, colInfo);
        const cellInProg = [AsyncCellState.Processing, AsyncCellState.Redecorating].includes(isAsyncCellEditing);

        // we don't want generate a editor component for renderers that are already inline editors
        const inlineEditor = colInfo.inputType === 'checkbox' || colInfo.renderer === 'starEditor';
        const finalEditable = !cellInProg && isEditable(params) && !inlineEditor;
        return finalEditable;
      },
      comparator: (valueA: string | undefined, valueB: string | undefined) => {
        let compValue = 0;
        if (isNil(valueA)) compValue = -1;
        else if (isNil(valueB)) compValue = 1;
        // we know it's not null above, but the check is "lost" as the function potentially escapes scope
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        switch (colInfo.comparator ? colInfo.comparator!.type : '') {
          case 'datetime':
            const [dateA, dateB] = [[valueA], [valueB]];

            if (colInfo.comparator && colInfo.comparator.options && colInfo.comparator.options.format) {
              const comparatorFormat = colInfo.comparator.options.format;
              dateA.push(comparatorFormat);
              dateB.push(comparatorFormat);
            }

            const momentA = moment(...dateA);
            const momentB = moment(...dateB);

            if (momentA.isAfter(momentB)) {
              compValue = 1;
            } else if (momentA.isBefore(momentB)) {
              compValue = -1;
            } else {
              compValue = 0;
            }
            break;
          case 'number':
            const numA = Number(valueA);
            const numB = Number(valueB);

            if (numA > numB) {
              compValue = 1;
            } else if (numB > numA) {
              compValue = -1;
            } else {
              compValue = 0;
            }
            break;
          default:
            // Fallback comparator for arrays (like in lifecycle), as ag-grid doesn't know how to sort those
            // We don't know if a valid value is rendered as 'ALL', so we sort using the underlying items
            const valueAa = isArray(valueA)
              ? valueA
                  .sort()
                  .filter((x) => x != 'Undefined')
                  .toString()
              : valueA;
            const valueBb = isArray(valueB)
              ? valueB
                  .sort()
                  .filter((x) => x != 'Undefined')
                  .toString()
              : valueB;
            // Default comparator logic
            compValue = _.defaultComparator(valueAa, valueBb, false);
        }
        return compValue;
      },
      valueGetter: (params: ValueGetterParams) => {
        const calculation = params.colDef.refData?.calculation as ParamedCalc | string;
        const field = params.colDef.field;
        const allowNonNumber = typeof calculation === 'object' ? calculation.allowNonNumber : false;

        if (!isNil(calculation) && calculation !== 'unknown') {
          const newValue = executeCalculation(this.math, calculation, getDataFn(params), allowNonNumber);
          return newValue;
        }

        // special case for arrayEditor renderers, which need to pull their respective elements from an array field
        if (
          params.data &&
          // @ts-ignore
          params.colDef.refData?.inputParams?.useArrayEdit &&
          params.colDef.refData?.arrayDataIndex
        ) {
          // try to safely get data out of an array column from the pivot
          const arrayValues = get(params.data, params.colDef.refData.arrayDataIndex);
          const arrayOrders = get(params.data, params.colDef.refData.arrayOrderDataIndex) as unknown[];
          const arrayElementKey = params.colDef.refData.arrayElementKey;
          const arrayElementIndex = arrayOrders?.findIndex((d) => d === arrayElementKey);
          return get(arrayValues, arrayElementIndex);
        }
        if (params.data && field && typeof params.data[field] == 'number') {
          return params.data[field];
        }
        if (params.data && field && !isNil(params.data[field])) {
          return params.data[field];
        }

        // special case for checkbox renderer, for if darwin sends empty string (which js treats as falsy), to avoid the falsy value getting converted to null below
        if (params.data && params.colDef.refData?.renderer === 'checkbox' && field && params.data[field] === '') {
          return false;
        }

        return null;
      },
      valueSetter: (params: ValueSetterParams): boolean => {
        const { newValue, data, colDef, node } = params;
        const { columnDefs } = this.props;
        const field = colDef.field;
        const fieldConfig = columnDefs.find((item) => item.dataIndex === field);
        let potentialUndefDec = false;
        if (fieldConfig?.calculation != null && node != null) {
          this.updateCalcEdit({ node, value: newValue, colDef });
        }
        if (
          params.data &&
          // @ts-ignore
          params.colDef.refData?.inputParams?.useArrayEdit &&
          params.colDef.refData?.arrayDataIndex
        ) {
          potentialUndefDec = true;
          const arrayValues = get(params.data, params.colDef.refData.arrayDataIndex);
          const arrayOrders = get(params.data, params.colDef.refData.arrayOrderDataIndex) as unknown[];
          const arrayElementKey = params.colDef.refData.arrayElementKey;
          const arrayElementIndex = arrayOrders?.findIndex((d) => d === arrayElementKey);
          arrayValues[arrayElementIndex] = newValue;
          node?.setData({
            ...params.data,
            [arrayElementIndex]: newValue,
          });
        } else if (newValue && !isEmpty(newValue.storeData)) {
          potentialUndefDec = true;
          // Saving for store eligibility
          const storeDataByFloorset = Array.isArray(newValue.storeData[floorset])
            ? newValue.storeData[floorset][0]
            : newValue.storeData[floorset];
          if (storeDataByFloorset) {
            data[ATTR_GRADE] = storeDataByFloorset['grade'];
            data[ATTR_CLIMATE] = storeDataByFloorset['strclimate'];
            data[ATTR_MENSCAPACITY] = storeDataByFloorset['strmenscapacity'];
            data[ATTR_WOMENSCAPACITY] = storeDataByFloorset['strwomenscapacity'];
            data[ATTR_SSG] = storeDataByFloorset['ssg:ids']
              ? storeDataByFloorset['ssg:ids']
              : storeDataByFloorset['ssg'];
            data[ATTR_FUNDED] = storeDataByFloorset['isfunded'];
            data[STORE_COUNT] = storeDataByFloorset[STORE_COUNT];
          }

          // Saving lifecycle
          if (!isEmpty(newValue.lifecycleData)) {
            const lifecycleParsedData = {};
            Object.keys(newValue.lifecycleData).forEach((key) => {
              // Lifecycle data doesn't have attribute in the dataindex so this tries to cover the bases
              lifecycleParsedData[key] = newValue.lifecycleData[key];
              lifecycleParsedData[`attribute:${key}:id`] = newValue.lifecycleData[key];
              lifecycleParsedData[`attribute:${key}:name`] = newValue.lifecycleData[key];
            });
            node?.setData({
              ...data,
              ...lifecycleParsedData,
            });
          }
        } else if (fieldConfig?.inputType === 'receiptsAdjCalculator' && field != null && newValue) {
          data[USERADJ] = newValue['userAdjRevision'];
          data[ONORDERREVISION] = newValue['onOrderRevision'];

          const extraData = pickBy(
            data[field],
            (_v, k) => ['onOrderRevision', 'userAdjRevision', 'onOrderFinal', 'rcptFinal'].indexOf(k) < 0
          );
          forEach(extraData, (v, k) => {
            data[k] = v;
          });
        } else if (fieldConfig?.inputType === 'salesAdjustment' && field != null && newValue) {
          data[field] = newValue;
        } else if (colInfo.inputType === 'configurableDataModal') {
          forEach(newValue, (value, key) => {
            data[key] = value;
          });
        } else if (field) {
          // if async validation, newValue will be 'PENDING'
          data[field] = newValue;

          const memberMatched = field.match(/member:([a-z]*):[a-z]*/);
          const isMemberUpdate = !isNil(memberMatched);

          if (isMemberUpdate) {
            potentialUndefDec = true;
            const memberLevel = !isNil(memberMatched) ? memberMatched[1] : '';

            if (
              isObject(newValue) &&
              'label' in newValue &&
              typeof newValue.label === 'string' &&
              'value' in newValue &&
              typeof 'value' === 'string'
            ) {
              // ensure all dependentData for memberLevel is updated for dataApi lookups later
              data[`member:${memberLevel}:id`] = newValue.value;
              data[`member:${memberLevel}:name`] = newValue.label;
              data[`member:${memberLevel}:description`] = newValue.label;
            } else {
              const pendingCellInfo: PendingCellInfo = {
                id: node?.id || '',
                dataIndex: !isNil(params.column) ? params.column.getColId() : '',
              };

              // this method handles correctly updating async cell updates
              // it will override the 'data[field] = newValue' value set above
              this.handlePendingCellUpdate(newValue, pendingCellInfo);
            }
          }
        }

        if (!isNil(params.node) && isGroupNode(params.node)) {
          potentialUndefDec = true;
          // Manually call coarse update for group nodes, avoiding conflicts within cellValueChanged
          const field = colDef.field;
          const fieldConfig = columnDefs.find((item) => item.dataIndex === field);
          if (fieldConfig && fieldConfig.cascadeGroupSelection) {
            this.submitCoarseUpdate(params.newValue, [params.node], colDef);
          } else {
            this.logger.warn(
              `Attempted to edit group node ${params.node.id} without cascadeGroupSelection. Performing no action.`
            );
          }
        }
        if (potentialUndefDec && fieldConfig?.calculation != null) {
          this.logger.warn(`⚠️ UNDEFINED WARNING (INT-4228|INT-4523):
            Modifying calculated fields against certain field types is untested. If you see this, you may be experiencing weird bugs.
            Specifically, calc editing was designed for base input edits and was also tested against receipt/sales popover edits. Any
            other field types | attribute/member | group updates may (or may not) behavior weirdly.
          `);
        }
        return true;
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.colDef.field === 'dc_publish') {
          switch (params.value) {
            case 2:
              return 'Published';
            case 1:
              return 'Partial';
            default:
              return '';
          }
        } else if (!isNil(colInfo.renderer) && hasIn(Renderer, colInfo.renderer)) {
          const rawValue = params.value;
          // nil/NaN need to return undef here, because ag-grid uses that value in
          // the default filterValueGetter in order to replace undef with the string (Blanks)
          // in the column filter menu
          if (isNil(rawValue) || Number.isNaN(rawValue)) return undefined;
          return Renderer.renderJustValue(rawValue, colInfo);
        } else {
          // if the value goes down this path, it ends up in the default ag-grid renderer,
          // which aproximates params.value.toString()
          return params.value;
        }
      },
      cellEditorSelector: (params: ICellEditorParams): CellEditorSelectorResult => {
        let row: IRowNode;
        if (!params) {
          return null as unknown as CellEditorSelectorResult;
        }

        if (params.node == null) {
          return null as unknown as CellEditorSelectorResult;
        } else {
          row = params.node;
        }

        const styleColor = coalesce(this.gridApi.getValue('id', row), get(params.data, 'id'));
        const processedDataApi = this.getRowProcessedApi(row, colInfo.dataApi);
        const processedConfigApi = this.getRowProcessedApi(row, colInfo.configApi);

        switch (colInfo.inputType) {
          case 'select':
            return {
              component: 'agRichSelect',
              params: {
                values: map('value', colInfo.options),
              },
            };
          case 'lifecycleParameters':
            params.colDef.cellEditorPopup = true;
            const headerSubtext = `
              ${params.data['name']} | ${params.data['description']}`;
            return {
              component: 'lifecycleParametersEditor',
              params: {
                tabIndex: colInfo.tabIndex,
                dataApiLifecycle: {
                  ...colInfo.dataApiLifecycle,
                  params: {
                    appName: 'assortment',
                    product: styleColor,
                    ...get(colInfo.dataApiLifecycle, 'params', {}),
                  },
                },
                dataApiStore: {
                  ...colInfo.dataApiStore,
                  params: {
                    appName: 'assortment',
                    product: styleColor,
                    ...get(colInfo.dataApiStore, 'params', {}),
                  },
                },
                lifecycleConfig: {
                  ...colInfo.lifecycleConfig,
                  params: !isNil(colInfo.lifecycleConfig) ? { ...colInfo.lifecycleConfig.params } : {},
                },
                storeConfig: {
                  ...colInfo.storeConfig,
                  params: !isNil(colInfo.storeConfig) ? { ...colInfo.storeConfig.params } : {},
                },
                dependentsApi: {
                  ...colInfo.dependentsApi,
                },
                floorset: floorset,
                product: styleColor,
                headerSubtext,
              },
            };
          case 'salesAdjustment':
            params.colDef.cellEditorPopup = true;
            return {
              component: 'salesAdjustmentEditor',
              params: {
                dataApi: {
                  ...colInfo.dataApi,
                },
                configData: this.props.salesAdjustmentConfig,
                floorset: this.props.activeFloorset,
                isEditable: colInfo.editable || false,
              },
            };
          case 'receiptsAdjCalculator': {
            params.colDef.cellEditorPopup = true;

            const floorset = this.props.activeFloorset;
            const dataApi = isEmpty(processedDataApi?.params)
              ? getRecAdjDataApi(params.data.id, floorset)
              : processedDataApi;
            const configApi = processedConfigApi ? processedConfigApi : TEMP_REC_ADJ_CONFIG_API;
            return {
              component: 'receiptsAdjCalculator',
              params: {
                isEditable: colInfo.editable || false,
                dataApi,
                configApi,
                floorset,
              },
            };
          }
          case 'configurableDataModal': {
            params.colDef.cellEditorPopup = true;

            const cellDataIndex = colInfo.dataIndex;
            return {
              component: 'configurableDataModal',
              params: {
                isEditable: colInfo.editable,
                configApi: {
                  url: colInfo.configApi.url,
                },
                floorset: this.props.activeFloorset,
                cellDataIndex,
                renderTabs: colInfo.renderModalTabs,
              },
            };
          }
          case 'validValues':
          case 'validValuesMulti': {
            params.colDef.cellEditorPopup = true;

            const multiSelect = colInfo.inputType === 'validValuesMulti' ? true : undefined;
            const dataQa = isNil(multiSelect) ? 'select-configurable-grid' : 'select-multi-configurable-grid';
            const allowEmptyOption = isNil(colInfo.allowEmptyOption) ? true : colInfo.allowEmptyOption;
            // only return full object on member updates
            const returnSelectionObject = colInfo.dataIndex.match(/member:([a-z]*):[a-z]*/);
            return {
              component: 'validValuesEditor',
              params: {
                dataConfig: processedConfigApi || processedDataApi,
                dataQa,
                multiSelect,
                asCsv: colInfo.asCsv,
                postArrayAsString: colInfo.postArrayAsString,
                allowEmptyOption,
                returnSelectionObject,
                ignoreCache: colInfo.ignoreCache,
                includeCurrent: colInfo.includeCurrent,
                concatOptionValues: colInfo.concatOptionValues,
              },
            };
          }
          case 'textValidator':
          case 'textValidatorAsync': {
            params.colDef.cellEditorPopup = true;

            const inputParams = colInfo.inputParams;
            const whitelist = viewDefnWhitelistToNarrowedCharacterWhitelist(inputParams.whitelist);
            const pendingCellInfo: PendingCellInfo = {
              id: params.node?.id || '',
              dataIndex: !isNil(params.column) ? params.column.getColId() : '',
            };
            return {
              component: 'textValidationEditor',
              params: {
                validateAsync: colInfo.inputType === 'textValidatorAsync',
                invalidDataIndex: colInfo.invalidDataIndex,
                ...inputParams,
                whitelist,
                pendingCellInfo,
                onValidated: this.handlePendingCellUpdate.bind(this), // will be invoked in promise context, so need to set context
              },
            };
          }
          case 'integer':
            const int = params.data[this.props.activeStyleColor];
            const percent = colInfo.renderer && PERCENT_RENDERERS.indexOf(colInfo.renderer) > -1;
            return {
              component: 'integerEditor',
              params: {
                passedInt: int,
                inputParams: { ...colInfo.inputParams, percent },
              },
            };
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'validSizes':
            params.colDef.cellEditorPopup = true;
            return {
              component: 'validSizesEditor',
              params: {
                dataApi: {
                  url: colInfo.dataApi.url,
                  params: mapValues(colInfo.dataApi.params, (v, _k) => {
                    return row.data[v];
                  }),
                  headers: colInfo.dataApi.headers,
                },
              },
            };
          default: {
            return {
              component: 'agTextCellEditor',
            };
          }
        }
      },
      cellRendererSelector: (params: ICellRendererParams): CellRendererSelectorResult => {
        let row: IRowNode;
        if (!params || params.node == null) {
          return null as unknown as CellRendererSelectorResult;
        }

        if (params.node.aggData) {
          // first group modification in ConfigurableGrid. Checkbox is only current inline-renderer/editor
          // with group modification support.
          // When cascadeGroup, allow the field to be editable, then handle the result on change.
          if (colInfo.renderer === 'checkbox') {
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: colInfo.cascadeGroupSelection,
                // This treats the field as true|false|(null|undef). In null undef case, field shows "[-]"
                allowIndeterminate: true,
              },
            };
          }
          if (colInfo.renderer === 'validValuesCheckbox') {
            const availableSelections = colInfo.options ? colInfo.options.map((c) => c.value) : [];
            // this will be true if it's grouping
            return {
              component: 'validValuesCheckbox',
              params: {
                isEditable: true,
                availableSelections,
                group: true,
                optionsApi: colInfo.dataApi,
                validValuesCache: this.props.validValuesCache,
              },
            };
          }
          // TODO: Handle aggregate edits for framework components in Renderer.tsx
          if (frameworkComponents[colInfo.renderer || ''] && PERCENT_RENDERERS.includes(colInfo.renderer || '')) {
            return {
              component: colInfo.renderer,
            };
          } else {
            return null as unknown as CellRendererSelectorResult;
          }
        } else {
          row = params.node;
        }
        switch (colInfo.renderer) {
          case 'image':
            return {
              component: 'imageCellRenderer',
            };
          case 'imageWithHover':
            return {
              component: 'imageRendererWithHover',
            };
          case 'validValuesCheckbox': {
            const availableSelections = colInfo.options ? colInfo.options.map((c) => c.value) : [];
            // If SSG is present, disable checkboxes
            const ssg = coalesce(this.gridApi.getValue(ATTR_SSG, row), get(row.data, ATTR_SSG));
            const isEditable = !(ssg && isArray(ssg) && ssg.length > 0);
            return {
              component: 'validValuesCheckbox',
              params: {
                isEditable,
                availableSelections,
                optionsApi: colInfo.dataApi,
                validValuesCache: this.props.validValuesCache,
              },
            };
          }
          case 'icon':
            let value = params.value;
            value = value && value[0] && value[0].value ? value[0].value.id : value;
            let icon = colInfo.rendererIcon;

            if (params.colDef && params.colDef.field === 'attribute:cccolor:id') {
              const isLocked = params.data['is_locked'];
              if (isLocked === 1) {
                icon = colInfo.rendererIcon2 || '';
              }
            }
            if (params.colDef && params.colDef.field === 'attribute:isfunded:id') {
              if (value === 1) {
                icon = colInfo.rendererIcon2 || '';
              }
              value = undefined;
            }

            const rendererParams = {
              icon,
              value,
            };

            return {
              component: 'iconCellRenderer',
              params: rendererParams,
            };
          case 'adornmentsGridRenderer': {
            const productId = get(params.data, colInfo.dataIndex ?? 'id');
            return {
              component: 'adornmentsGridRenderer',
              params: {
                adornments: this.props.adornments,
                productId,
              },
            };
          }
          case 'iconWithPopoverTrigger': {
            return {
              component: 'iconWithPopoverTrigger',
              params: {
                onItemClicked: (item: BasicPivotItem) => {
                  if (this.props.onPopoverItemClicked) {
                    this.props.onPopoverItemClicked(item);
                  }
                },
                icon: colInfo.rendererIcon,
                dataQa: 'StylePaneTrigger',
                rendererParams: colInfo.rendererParams,
                classes: colInfo.classes,
              },
            };
          }
          case 'checkbox':
            return {
              component: 'checkboxCellRenderer',
              params: {
                isEditable: isEditable(params),
              },
            };
          case 'link':
            return {
              // reusing iconWithPopover for link also
              component: 'iconWithPopoverTrigger',
              params: {
                onItemClicked: (item: BasicPivotItem) => {
                  const processedDataApi = processApiParams(colInfo.dataApi, item);
                  const url = getUrl(processedDataApi);
                  if (colInfo.breadcrumb) {
                    const currentLocation = extractNavPathFromString(window.location.hash);
                    const newLocation = extractNavPathFromString(url.split('?')[0]); // remove the new query param from the crumb
                    if (isSome(currentLocation) && isSome(newLocation)) {
                      // in the future, we shouldn't resent crumbs each time, as we could be coming from another crumb
                      this.props.resetCrumbs(colInfo.breadcrumb);
                      this.props.addCrumb([colInfo.breadcrumb, [currentLocation.value, newLocation.value]]);
                    }
                  }

                  // TODO replace this with a router function call
                  window.location.hash = url;
                },
                icon: colInfo.rendererIcon,
                dataQa: 'LinkRenderer',
                rendererParams: colInfo.rendererParams,
                classes: colInfo.classes,
              },
            };
          case 'range_picker':
            return {
              component: 'rangePickerRenderer',
              params: colInfo,
            };
          case 'validSizes':
            return {
              component: 'validSizesRenderer',
              params: {
                dataConfig: {
                  url: colInfo.dataApi.url,
                  params: mapValues(colInfo.dataApi.params, (_v, k) => {
                    return row[k];
                  }),
                  headers: colInfo.dataApi.headers,
                },
              },
            };
          case 'validValuesRenderer':
            // FIXME: EAS-607, fix configs to be consistent so we only target dataApi and not configApi.
            const api = isNil(colInfo.configApi) ? colInfo.dataApi : colInfo.configApi;
            const dataConfig = !isNil(api) ? processApiParams(api, row) : null;
            return {
              component: 'validValuesRenderer',
              params: {
                dataConfig,
              },
            };
          case 'tooltipRenderer':
            return {
              component: 'tooltipRenderer',
            };
          case 'severityRender':
            return {
              component: 'severityRender',
            };
          case 'starPercentRenderer':
            return {
              component: 'starPercentRenderer',
            };
          case 'starEditor':
            return {
              component: 'starEditor',
            };
          case 'statusIconRenderer':
            return {
              component: 'statusIconRenderer',
            };
          default:
            const maybeValue = params.value || (params.getValue && params.getValue());
            if (frameworkComponents[colInfo.renderer || ''] && maybeValue) {
              return {
                component: colInfo.renderer,
                params: {
                  config: colInfo,
                },
              };
            } else {
              return null as unknown as CellRendererSelectorResult;
            }
        }
      },
    };
  };

  createGroupedColumns = (columnDefs: ConfigurableGridConfigItem[]) => {
    // FIXME move this array somewhere else
    const arrayEditorColumns: ColDef[] = [];
    const groupedColDefs: (ColDef | ColGroupDef)[] = [];
    const enableFloatingFilterRow = this.enableFloatingFiltersRow();

    const colDefs = columnDefs
      .map((cd) => this.createColumnDef(cd, enableFloatingFilterRow))
      .map((col) => {
        col.suppressKeyboardEvent = (params: SuppressKeyboardEventParams) => {
          if (params.colDef.field && BLOCK_ENTER_EDITORS.includes(col.refData?.inputType || 'unknown')) {
            if (params.editing && POPOVER_BLOCK_CODES.includes(params.event.code)) {
              return true;
            }
          }
          return false;
        };
        return col;
      });

    const finalColumnDefs = colDefs.sort().flatMap((c) => {
      // @ts-ignore
      if (c.refData?.inputParams?.useArrayEdit && c.colId && this.state.arrayEditorBaseValues[c.colId]) {
        const length = this.state.arrayEditorBaseValues[c.colId].values.length;
        return this.state.arrayEditorBaseValues[c.colId].values.map((elem: Suggestion, idx: number) =>
          this.createArrayEditorColumns(elem, idx === length - 1, this.getAssociatedConfigCol(c)!)
        );
      }
      return c;
    });

    // check for groupBy and add grouping column
    const groupBy = !isNil(this.props.groupBySelection) ? this.props.groupBySelection.option : null;
    if (groupBy) {
      const selGroupCol = find(finalColumnDefs, (colDef) => colDef.field === groupBy.dataIndex);
      if (selGroupCol) {
        selGroupCol.rowGroupIndex = 0;
        selGroupCol.rowGroup = true;
      } else if (!isEmpty(groupBy.dataIndex)) {
        finalColumnDefs.unshift({
          field: groupBy.dataIndex,
          rowGroupIndex: 0,
          hide: true,
          rowGroup: true,
        });
      }
    } else if (!isNil(this.props.configureSelections) && !isEmpty(this.props.configureSelections)) {
      this.props.configureSelections.map((groupBy, ind) => {
        const selGroupCol = find(finalColumnDefs, (colDef) => colDef.field === groupBy.dataIndex);
        if (selGroupCol) {
          selGroupCol.rowGroupIndex = ind;
          selGroupCol.rowGroup = true;
        } else if (!isEmpty(groupBy.dataIndex)) {
          finalColumnDefs.unshift({
            field: groupBy.dataIndex,
            rowGroupIndex: ind,
            hide: true,
            rowGroup: true,
          });
        }
      });
    }
    let groupTemp: ColDef[] = [];
    function isLastInGroup(defs: ConfigurableGridConfigItem[], start: number, key: string, pinned: boolean): boolean {
      const def = defs[start];
      if (!def) return true;
      // We skip any columns that are either hidden or in a different position
      // For pinned items, we only look at pinned items, same for non-pinned items
      if (def.hidden || def.visible === false || def.pinned !== pinned) {
        return isLastInGroup(defs, start + 1, key, pinned);
      }
      if (def.groupingKey != key) {
        return true;
      }
      return false;
    }

    finalColumnDefs.forEach((agColDef) => {
      // Find the relevant column in view defn for context via dataIndex
      let colDefIndex = findIndex(columnDefs, (cDef) => cDef.dataIndex === agColDef.field);
      if (agColDef.refData?.arrayDataIndex != null) {
        colDefIndex = findIndex(columnDefs, (cDef) => cDef.dataIndex === agColDef.refData?.arrayDataIndex);
      }
      const colDef = columnDefs[colDefIndex];
      if (isNil(colDef)) {
        groupedColDefs.push(agColDef);
        return;
      }
      if (!colDef.groupingKey) {
        // don't push heatmapRenderer columns into group since they are handled separately
        if (agColDef.cellRenderer != 'heatmapRenderer') {
          groupedColDefs.push(agColDef);
        }
      } else {
        if (colDef.renderer && colDef.columns && colDef.renderer === 'size_array_configurable') {
          // make sure all size columns are under this group
          groupedColDefs.push({
            headerName: colDef.text,
            children: colDef.columns.map((size, idx, arr) => {
              return this.createSizeColDefs(
                size.id || '',
                idx,
                arr.map((s) => s.id || ''),
                colDef.dataIndex
              );
            }),
            headerGroupComponent: 'customGroupHeader',
          });
          groupTemp = [];
        } else {
          let lastInGroup = isLastInGroup(columnDefs, colDefIndex + 1, colDef.groupingKey, agColDef.pinned);
          // if there is an arrayDataIndex, we are looking at `useArrayEdit` columns
          // So we need to wait for the last column in the array group (in addition to the original columns' position)
          if (agColDef.refData?.arrayDataIndex != null) {
            const isLastIndex = agColDef.refData?.isLastIndex === true.toString();
            lastInGroup = lastInGroup && isLastIndex;
          }
          groupTemp.push(agColDef);
          if (lastInGroup) {
            groupedColDefs.push({
              headerName: colDef.groupingKey,
              children: groupTemp.slice(0),
              headerGroupComponent: 'customGroupHeader',
            });
            groupTemp = [];
          }
        }
      }
    });

    // Add the checkbox column when props indicate useful
    if (this.props.enableCheckboxSelection) {
      groupedColDefs.unshift({
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerName: '',
        width: 40,
        pinned: true,
        headerClass: headerCheckbox,
      });
    }

    // add in async cell state column
    const updatedColumnDefs = concat(groupedColDefs, {
      field: 'asyncstate',
      hide: true,
      editable: false,
    });
    return updatedColumnDefs;
  };

  generateColumnDefs = () => {
    const initialColumnDefs = this.props.columnDefs ? this.createGroupedColumns(this.props.columnDefs) : [];
    return initialColumnDefs;
  };

  createSizeColDefs = (size: string, index: number, sizes: string[], dataIndex = 'heatmap') => {
    return {
      field: size,
      colId: `sizeHeatMap_${size}`,
      headerName: size,
      width: 100,
      cellClass: 'size-heatmap-cell',
      cellRenderer: 'heatmapRenderer',
      cellRendererParams: {
        sizeArrayIndex: index,
        dataIndex,
        valueAsCssColor: dataIndex !== 'heatmap',
      },
      sizes,
    };
  };

  createArrayEditorColumns = (
    element: Suggestion,
    isLastIndex: boolean,
    colInfo: ConfigurableGridConfigItem
  ): ColDef => {
    const enableFloatingFiltersRow = this.enableFloatingFiltersRow();
    const colId = `${ARRAY_EDITOR_COLUMN_ID_PREFIX}_${colInfo.dataIndex}_${element.value}`.toLowerCase();

    const modifiedColInfo = {
      ...colInfo,
      dataIndex: colId,
      text: `${colInfo.text} ${element.label}`,
      refData: {
        // we stringify here since aggrid is expecting strings in refdata
        isLastIndex: isLastIndex.toString(), // used for column grouping (to determine if we're at the end of the array to close off group)
        arrayDataIndex: colInfo.dataIndex, // used to find the original column when updating the array back to the backend
        arrayOrderDataIndex: colInfo.arrayOrderDataIndex!, // required property, should always be there at this point, used to find the element order from the pivot
        arrayElementKey: element.value.toString(), // `refData` only allows strings, so toString() here
      },
    };
    return this.createColumnDef(modifiedColInfo, enableFloatingFiltersRow);
  };

  refreshGrid = () => {
    if (this.gridApi) {
      this.gridApi.redrawRows();
    }
  };

  getRowNodeValues = (dataIndex: string, nodes: IRowNode[], isSpecialAgg = false) => {
    const nodeValues = nodes.map((node) => {
      let value = coalesce(
        this.gridApi.getValue(dataIndex, node),
        get(node.data, dataIndex),
        get(node.data, dataIndex.split(':')[1])
      );
      // special aggs use their own logic that are not math calculations, so should not be coerced
      if (!isNumber(value) && !isSpecialAgg) {
        value = 0;
      }

      return value;
    });

    return !isSpecialAgg ? filter(nodeValues, (i) => !isNil(i)) : nodeValues;
  };

  groupRowAggNodes = (params: GetGroupRowAggParams) => {
    const { nodes } = params;
    if (nodes.length === 0) {
      return;
    }
    let colApi = this.columnApi;
    if (isNil(colApi)) {
      colApi = (nodes[0] as any).columnApi;
    }
    // get and store all aggregator or aggregatorFunction values from column configs
    const aggTypes: Record<string, string> = {};
    const aggResults: Record<string, unknown> = {};
    const columns = colApi.getColumns();

    columns?.forEach((column) => {
      const colId: string = column.getColId();
      const arrayColId = column.getColDef().refData?.arrayDataIndex;
      const configColumn = this.props.columnDefs.find((def) => {
        return def.dataIndex === colId || def.dataIndex === arrayColId;
      });
      if (!isNil(configColumn)) {
        const { aggregator, aggregatorFunction } = configColumn;
        const dataIndex = column.getColId();
        // We have special logic for checkbox "aggregation" (through indeterminance)
        if (configColumn.renderer === 'checkbox') {
          aggTypes[dataIndex] = 'checkbox';
          return;
        }
        if (configColumn.renderer === 'validValuesCheckbox') {
          aggTypes[dataIndex] = 'validValuesCheckbox';
          return;
        }
        if (isNil(aggregator) && isNil(aggregatorFunction)) {
          return;
        }
        if (aggregator === 'eval' && isString(aggregatorFunction)) {
          aggTypes[dataIndex] = aggregatorFunction;
        } else if (isString(aggregator)) {
          aggTypes[dataIndex] = aggregator;
        } else if (isString(aggregatorFunction)) {
          aggTypes[dataIndex] = aggregatorFunction;
        }
      }
    });

    forEach(aggTypes, (value: string, key) => {
      // handle normal aggregations
      const isSpecialAgg = ['checkbox', 'validValuesCheckbox'].indexOf(value) >= 0;
      const nodeValues = this.getRowNodeValues(key, nodes, isSpecialAgg);
      if (isEmpty(nodeValues)) {
        return;
      }

      switch (value) {
        case 'sum':
        case 'min':
        case 'max': {
          const result = this.math[value](nodeValues);
          aggResults[key] = result;
          break;
        }
        case 'count': {
          const count = this.math.size(nodeValues);
          aggResults[key] = count;
          break;
        }
        case 'avg': {
          const avg = this.math.mean(nodeValues);
          aggResults[key] = avg;
          break;
        }
        case 'checkbox': {
          const allThere = every(nodeValues, (v) =>
            v === 'true' || v === true || (isNumber(v) && v > 0) ? true : false
          );
          const someThere = some(nodeValues, (v) =>
            v === 'true' || v === true || (isNumber(v) && v > 0) ? true : false
          );
          // For indeterminance, when only *some* are "selected", we set to the unknown "null"
          aggResults[key] = allThere ? true : someThere ? null : false;
          break;
        }
        case 'validValuesCheckbox': {
          const flatValues: string[] = flatten(nodeValues);
          const valueTotals = reduce(
            flatValues,
            (totals, value) => {
              if (has(totals, value)) {
                totals[value] += 1;
              } else {
                totals[value] = 1;
              }
              return totals;
            },
            {} as Record<string, number>
          );
          aggResults[key] = valueTotals;
          break;
        }
        default: {
          // handle custom aggregations
          const column = colApi.getColumn(key);
          const colId = column?.getColId();
          const configColumn = this.props.columnDefs.find((def) => {
            return def.dataIndex === colId;
          });

          if (!isNil(configColumn) && !isNil(configColumn.aggregatorFunction)) {
            // parse and get expression dataIndices to calculate
            const parsedExpression = this.math.parse(configColumn.aggregatorFunction);
            const expressionNames = flow(
              () =>
                parsedExpression.filter(
                  (node) => node.isSymbolNode && node.name !== 'AGG' && !has(this.math, node.name ?? '')
                ),
              map((node) => node.name || '_')
            )();

            const aggregationHandler = partial(this.handleCustomAggregation, [
              nodes,
              parsedExpression,
              expressionNames,
            ]);
            aggResults[key] = aggregationHandler(value);
          }
        }
      }
    });

    return aggResults;
  };

  handleCustomAggregation = (
    nodes: IRowNode[],
    parsedExpression: globalMath.MathNode,
    expressionNames: string[],
    _aggregatorFunction: string
  ) => {
    // get values for expression
    const exprValues = flow(
      () => expressionNames,
      reduceFP((acc, id = '') => {
        acc[id] = coalesce(this.getRowNodeValues(id, nodes), []);
        return acc;
      }, {})
    )();

    let result;
    try {
      result = parsedExpression.evaluate({ ...exprValues });
    } catch (error) {
      console.warn('error calculating aggregation:', error); // eslint-disable-line no-console
    }

    if ((isNumber(result) && (isNaN(result) || !isFinite(result))) || isNil(result)) {
      result = 0;
    }

    return result;
  };

  getPostObject = (field: string, value: PostValue, data: BasicPivotItem, parentData: string[] = []) => {
    // ccseason is a style attribute
    const id = data[this.props.leafIdProp];

    if (isEmpty(parentData)) {
      // regular attribute

      let val = !isNil(value) ? value : '';
      if (val === true) {
        // these checks specificaly guard against a nil value being sent back as a zero-length string
        // and can instead be returned as the string 'true' or 'false'
        val = 'true';
      } else if (val === false) {
        val = '';
      }

      return {
        id,
        [field]: val,
      };
    }

    return {
      id,
      parent: parentData,
    };
  };

  getSelectedRows = (): PivotBasicItem[] => {
    if (this.gridApi == null) return [];
    const selectedNodes: IRowNode[] = this.gridApi.getSelectedNodes();
    const floorsetId = this.props.activeFloorset;
    return selectedNodes
      .filter((n) => {
        return isNil(n.allChildrenCount) || n.allChildrenCount <= 0;
      })
      .map((n) => {
        const rowData = n.data;
        rowData.floorset = floorsetId;
        return rowData;
      });
  };

  getAssociatedConfigCol = (colDef: ColDef) => {
    const configedColumn = this.props.columnDefs.find((col) => {
      return (
        (col.dataIndex === colDef.field &&
          // due to a renderer needing to exist, we check against the hardset "unknown" as that is applied
          // by the col gen function when renderer is null
          (col.renderer || 'unknown') === colDef.refData?.renderer &&
          col.inputType === colDef.refData.inputType) ||
        // @ts-ignore
        (colDef.refData?.inputParams?.useArrayEdit && colDef.refData.arrayDataIndex === col.dataIndex)
      );
    });
    return configedColumn;
  };

  getArrayEditorValues = (
    colDef: ReturnType<EditableGrid['createColumnDef']>,
    rowNode: IRowNode,
    editedArrayElementKey: string | undefined,
    editValue: unknown
  ) => {
    const originalColDefDataIndex = this.getAssociatedConfigCol(colDef)?.dataIndex;
    const arrayValueColumns = this.gridApi
      .getAllGridColumns()
      .filter((c) => c.getColDef().refData?.arrayDataIndex === originalColDefDataIndex);
    const arrayOrders = arrayValueColumns.map((c) => c.getColDef().refData?.arrayElementKey);
    const arrayValues = arrayValueColumns.map((c) => {
      // coerce to null here for json reasons
      const retrievedValue = this.gridApi.getValue(c, rowNode) ?? null;

      // when handling header editor updates, the grid will not have the correct value,
      // so when values are different on the same column that was edited, take editValue over retrieved value

      return c.getColDef().refData?.arrayElementKey === editedArrayElementKey && !isEqual(retrievedValue, editValue)
        ? editValue
        : retrievedValue;
    });

    return {
      values: arrayValues,
      orders: arrayOrders,
    };
  };

  columnUsesGenericUpdate = (colDef: ColDef) => {
    return (
      this.getAssociatedConfigCol(colDef as unknown as ReturnType<EditableGrid['createColumnDef']>)
        ?.useMassEditUpdate === true
    );
  };

  submitMassColumnUpdate = async (params: MassColumnUpdateParams) => {
    const massEditConfig = this.props.massEditConfig;
    if (isNil(massEditConfig)) {
      logError(
        `Cannot update ${get(params, 'dataIndex', '')} without massedit configured in useMassEditUpdate mode.`,
        null
      );
      return;
    }

    const coordinates = params.nodes.map((rowData) =>
      omitBy(
        mapValues(massEditConfig.coordinateMap, (v) => {
          const value = rowData[v];
          return value;
        }),
        isNil
      )
    );
    // FIXME: This should be running through the generic mass update.
    // Removing for now as this requires some significant changes to how we handle
    // the update and decorate
    const pKey = params.dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    await ServiceContainer.pivotService.coarseEditSubmitData({
      coordinates,
      [pKey]: params.value,
    });
  };

  redecorateRows = async (filteredItems: BasicPivotItem[], unfilteredItems: BasicPivotItem[], defnId: string) => {
    const { dataApi, leafIdProp } = this.props;

    if (dataApi.params) {
      try {
        const decoratedData = await ServiceContainer.pivotService.redecorate({
          coordinates: filteredItems,
          defnId,
          nestData: false,
          aggBy: dataApi.params.aggBy.split(','),
        });
        const redecoratedItems = reduce<BasicPivotItem, BasicPivotItem[]>(
          decoratedData,
          (accumulator, decoratedItem) => {
            // when filtering items (redecorateMap/onlySendCoordinateMap) need to retrieve the original values
            // since they will not be present in the redecorate response.
            const currentItem = unfilteredItems.find((t) => t[leafIdProp] == decoratedItem[leafIdProp]);
            const finalItem = { ...currentItem, ...decoratedItem };
            return concat(accumulator, finalItem);
          },
          []
        );
        return redecoratedItems;
      } catch {
        // instead of leaving cells stalled in redeco on error, set back to Idle
        updateNodeAsyncState(unfilteredItems[0], [leafIdProp], AsyncCellState.Idle);
        refreshGridCells(this.gridApi, unfilteredItems as unknown as IRowNode[], [leafIdProp]);
        toast(<div>Failed to process your update.</div>, {
          position: toast.POSITION.TOP_RIGHT,
          type: 'error',
        });
        return unfilteredItems;
      }
    } else return unfilteredItems;
  };

  // TODO: this is a first pass at cleaning up `submitGenericMassUpdate`
  // the grouping/multiNode logic is very similar and with a little bit of rework can be combined
  // more importantly we should be only passing a single set of nodes to handle when grouped,
  // no longer need updateChildren option by doing this just need to filter out group from item update

  /**
   * Handles persisting updates for cell value edits across rows and columns.
   *
   * Current use cases:
   * - group row cell edit being applied to all cell children (accounts for dependents)
   * - cell header editor edits being applied to selected children (accounts for dependents)
   * - single cell value edits
   */
  submitCoarseUpdate = async (value: unknown, nodes: IRowNode[], colDef: unknown) => {
    const {
      dataApi,
      massEditConfig,
      updateCoordinateMap,
      syncGridData,
      onlySendCoordinateMap,
      redecorateMap,
      leafIdProp,
    } = this.props;
    const typedColDef = colDef as unknown as ReturnType<EditableGrid['createColumnDef']>;
    const configColumn = this.getAssociatedConfigCol(typedColDef);
    const dataApiDefnId: string = dataApi.isListData ? dataApi.defnId : (dataApi.params?.defnId ?? '');
    const isMissingCoordinateMap = isNil(massEditConfig) && isNil(updateCoordinateMap);
    const coarsePayload: Record<string, unknown> = {};

    if (isMissingCoordinateMap || isNil(configColumn)) {
      const errorMessage = `Cannot update ${typedColDef.colId}. Somehow set to generic update without updateCoordinateMap property.`;
      logError(errorMessage, null);
      return;
    }

    if (configColumn?.inputParams?.useArrayEdit) {
      // intercept edit and forces use of granular
      this.submitGranularUpdate(value, nodes, colDef);
      return;
    }

    const { dataIndex, dependents = [] } = configColumn;
    const formattedDataIndex = dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
    const dataIndices: string[] = concat(dependents, dataIndex);

    // fall back to updateCoordinateMap when massEditConfig not present,
    // this is primarily used when view has cascading updates but no mass edit button.
    // type asserting because updateCoordinateMap is technically optional but check above confirms we have at least one
    const coordinateMap = (
      !isNil(massEditConfig) ? massEditConfig.coordinateMap : updateCoordinateMap
    ) as MassEditCoordinateMap;

    let formattedValue = value;
    // FIXME: comment or associated ticket number why is this needed
    if (isBoolean(value)) {
      formattedValue = value ? 'true' : '';
    }
    coarsePayload[formattedDataIndex] = formattedValue;

    let coordinateValues: EditCoordinates[] = [];

    if (isGroupNodeUpdate(nodes)) {
      if (isNil(value)) return;
      const node = nodes[0];

      // update group node async cell state
      updateNodeAsyncState(node, dataIndices, AsyncCellState.Processing);
      refreshGridCells(this.gridApi, [node], dataIndices);

      // update group node's children async cell state and store node's data for redecoration
      const itemsToUpdate: BasicPivotItem[] = node.allLeafChildren.map((rowNode) => {
        if (isGroupNode(rowNode)) return;
        updateNodeAsyncState(rowNode, dataIndices, AsyncCellState.Processing);
        return rowNode.data;
      });
      refreshGridCells(this.gridApi, node.allLeafChildren, dataIndices);

      // generate a set of coordinates for each row
      coordinateValues = itemsToUpdate.map((item) => generateCoordinateValues(coordinateMap, item));

      await ServiceContainer.pivotService.coarseEditSubmitData({
        coordinates: coordinateValues,
        ...coarsePayload,
      });

      // after submitting data, begin redecorating data

      updateNodeAsyncState(node, dataIndices, AsyncCellState.Redecorating, { updateNodeChildren: true });
      refreshGridCells(this.gridApi, [...node.allLeafChildren, node], dataIndices);

      if (!isEmpty(dataApiDefnId)) {
        const maybeFilteredItem = itemsToUpdate.map((i, idx) => {
          return filterItemForRedecorate(
            coordinateValues[idx],
            redecorateMap,
            onlySendCoordinateMap || false,
            i,
            leafIdProp
          );
        });
        const redecoratedData = await this.redecorateRows(maybeFilteredItem, itemsToUpdate, dataApiDefnId);
        this.gridApi.applyTransaction({ update: redecoratedData });
        syncGridData(redecoratedData);
        updateNodeAsyncState(node, dataIndices, AsyncCellState.Idle, { updateNodeChildren: true });
        refreshGridCells(this.gridApi, [...node.allLeafChildren, node], dataIndices);
        this.gridApi.refreshHeader(); // need to force refresh here for headitors
      } else {
        logWarn('`dataApi.defnId` not found in confdefn config, cannot redecorate the update.');
        itemsToUpdate.map((item) => {
          updateNodeAsyncState(item, dataIndices, AsyncCellState.Idle);
          return item;
        });
        this.gridApi.applyTransaction({ update: itemsToUpdate });
        refreshGridCells(this.gridApi, [node], dataIndices);
      }
    } else {
      // submit data

      updateAsyncState(nodes, dataIndices, AsyncCellState.Processing);
      refreshGridCells(this.gridApi, nodes, dataIndices);

      const itemsToUpdate: BasicPivotItem[] = nodes
        .map((rowNode) => {
          if (isGroupNode(rowNode)) return;
          return { ...rowNode.data, [dataIndex]: formattedValue };
        })
        .filter((data) => !isNil(data));
      coordinateValues = itemsToUpdate.map((item) => generateCoordinateValues(coordinateMap, item));

      await ServiceContainer.pivotService.coarseEditSubmitData({
        coordinates: coordinateValues,
        ...coarsePayload,
      });

      // redecorate data

      updateAsyncState(nodes, dataIndices, AsyncCellState.Redecorating);
      refreshGridCells(this.gridApi, nodes, dataIndices);

      if (!isEmpty(dataApiDefnId)) {
        const maybeFilteredItem = itemsToUpdate.map((i, idx) => {
          return filterItemForRedecorate(
            coordinateValues[idx],
            redecorateMap,
            onlySendCoordinateMap || false,
            i,
            leafIdProp
          );
        });
        const redecoratedData = await this.redecorateRows(maybeFilteredItem, itemsToUpdate, dataApiDefnId);
        this.gridApi.applyTransaction({ update: redecoratedData });
        syncGridData(redecoratedData);
        this.gridApi.refreshHeader(); // need to force refresh here for headitors
      } else {
        logWarn('`dataApi.defnId` not found in confdefn config, cannot redecorate the update.');
        this.gridApi.applyTransaction({ update: itemsToUpdate });
      }

      updateAsyncState(nodes, dataIndices, AsyncCellState.Idle);
      refreshGridCells(this.gridApi, nodes, dataIndices);
    }
  };

  submitGranularUpdate = async (value: unknown, nodes: IRowNode[], colDef: unknown) => {
    const {
      dataApi,
      updateCoordinateMap,
      leafIdProp,
      onlySendCoordinateMap = false,
      redecorateMap = undefined,
      syncGridData,
    } = this.props;
    const typedColDef = colDef as unknown as ReturnType<EditableGrid['createColumnDef']>;
    const configColumn = this.getAssociatedConfigCol(typedColDef);
    const dataApiDefnId: string = dataApi.isListData ? dataApi.defnId : (dataApi.params?.defnId ?? '');
    const unmodifiedValue = value;
    let modifiedValue = value;

    if (isNil(updateCoordinateMap) || isNil(configColumn)) {
      logWarn(`Cannot update ${typedColDef.colId} without massedit configured in useMassEditUpdate mode.`, null);
      return;
    }

    const {
      dataIndex,
      dependents = [],
      includedPropsInUpdate,
      calculation,
      arrayOrderDataIndex,
      inputParams,
    } = configColumn;

    // processing data

    const dataIndices: string[] = concat(dependents, typedColDef.colId!);
    updateAsyncState(nodes, dataIndices, AsyncCellState.Processing);
    refreshGridCells(this.gridApi, nodes, dataIndices);

    // This is an array to get around Typescript not properly processing type
    // due to it being in a if->Promise function - Mark :(
    // (alternative to) let newPromiseResolve: (() => void) | null
    const nextPromisesResolve: (() => void)[] = [];
    if (inputParams?.useArrayEdit) {
      // NOTE: YOU CANNOT HAVE _ANY_ `await` BEFORE THIS SWITCH OVER.
      // ANY `await` prior to this will cause the `arrayEditPromise` to be out of sync.
      const prev = this.arrayEditPromise;
      this.arrayEditPromise = new Promise((resolve) => {
        nextPromisesResolve.push(resolve);
      });
      await prev;
    }

    // FIXME: In order to match coarse behavior,
    // need to capture all unique leaf nodes and then reference that flattened list

    // generate and store payload for item(s) and itemsToUpdate for use during granular update/redecorate

    const { itemPayloads, filteredItems, unfilteredItems } = reduce(
      nodes,
      (acc, rowNode) => {
        if (isGroupNode(rowNode)) {
          return acc;
        }

        let itemPayload: GranularEditPayloadItem = { coordinates: {} as EditCoordinates };

        if (calculation != null) {
          modifiedValue = this.getCalcEdit({ node: rowNode, colDef: typedColDef, curValue: unmodifiedValue });
        } else if (inputParams?.useArrayEdit) {
          // intercept edit for array params here
          const arrayValuesAndOrders = this.getArrayEditorValues(
            typedColDef,
            rowNode,
            typedColDef?.refData?.arrayElementKey,
            unmodifiedValue
          );
          modifiedValue = arrayValuesAndOrders.values;
          if (arrayOrderDataIndex == null) {
            this.logger.error(`arrayOrderDataIndex missing from ${configColumn.dataIndex} column.`);
            throw `arrayOrderDataIndex missing from ${configColumn.dataIndex} column.`;
          } else {
            const formattedArrayOrderDataIndex = arrayOrderDataIndex
              .replace(/(member|attribute):/, '')
              .replace(/:(id|name|description)/, '');
            itemPayload[formattedArrayOrderDataIndex] = arrayValuesAndOrders.orders;
          }
        }

        // the following block was specifically for array edits,
        // must build item after modifying value when dealing with multiple items to prevent inadvertently writing over actual value with unmodified value

        const itemToUpdate: BasicPivotItem = { ...rowNode.data, [dataIndex]: modifiedValue };
        const coordinates: EditCoordinates = generateCoordinateValues(updateCoordinateMap, itemToUpdate);
        itemPayload = {
          ...itemPayload,
          coordinates,
        };

        // This fun mess removes that silly wrapped colon stuff (eg: attribute:<x>:id)
        // This is the more concise version: .replace(/(?:^.*?:)?([^:]*)(?::.*)?/, '$1')
        const formattedDataIndex = dataIndex.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');

        let calcValue = modifiedValue;
        if (isBoolean(modifiedValue)) {
          calcValue = modifiedValue ? 'true' : '';
        }
        itemPayload[formattedDataIndex] = calcValue;

        if (!isEmpty(includedPropsInUpdate)) {
          includedPropsInUpdate?.forEach((k) => {
            const formattedDataIndex = k.replace(/(member|attribute):/, '').replace(/:(id|name|description)/, '');
            const localValue = this.gridApi.getValue(k, rowNode);
            itemPayload[formattedDataIndex] = localValue;
          });
        }

        const filteredItemToUpdate = filterItemForRedecorate(
          coordinates,
          redecorateMap,
          onlySendCoordinateMap,
          itemToUpdate,
          leafIdProp
        );

        // immutability of accumulator doesn't matter here
        acc.itemPayloads.push(itemPayload);
        acc.filteredItems.push(filteredItemToUpdate);
        acc.unfilteredItems.push(itemToUpdate);

        return acc;
      },
      {
        itemPayloads: [] as GranularEditPayloadItem[],
        filteredItems: [] as BasicPivotItem[],
        unfilteredItems: [] as BasicPivotItem[],
      }
    );

    // submit data

    await ServiceContainer.pivotService.granularEditSubmitData(itemPayloads);

    // redecorate data and apply to redecoration to grid

    updateAsyncState(nodes, dataIndices, AsyncCellState.Redecorating);
    refreshGridCells(this.gridApi, nodes, dataIndices);

    if (!isEmpty(dataApiDefnId)) {
      const redecoratedData = await this.redecorateRows(filteredItems, unfilteredItems, dataApiDefnId);
      this.gridApi.applyTransaction({ update: redecoratedData });
      syncGridData(redecoratedData);
    } else {
      logWarn('`dataApi.defnId` not found in confdefn config, cannot redecorate the update.');
      this.gridApi.applyTransaction({ update: unfilteredItems });
    }

    // update complete
    nextPromisesResolve.forEach((p) => p());

    updateAsyncState(nodes, dataIndices, AsyncCellState.Idle);
    this.clearCalcEditTrackers(nodes);
    refreshGridCells(this.gridApi, nodes, dataIndices);
  };

  getToggledItems = (unfilteredSelectedNodes: IRowNode[]): IRowNode[] => {
    // for deselections, need to check against array with most items which is `this.state.selectedItems`
    const isDeselection = this.state.selectedItems.length > unfilteredSelectedNodes.length;
    const toggledItems: IRowNode[] = isDeselection
      ? difference(this.state.selectedItems, unfilteredSelectedNodes)
      : difference(unfilteredSelectedNodes, this.state.selectedItems);
    return toggledItems;
  };

  handleCheck = debounce(
    () => {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const groupNodes = selectedNodes.filter((node) => node.group);
      const hasGroupNodes = groupNodes.length > 0;
      const unfilteredNodes: IRowNode[] = [];

      this.gridApi.forEachNodeAfterFilter((n) => unfilteredNodes.push(n));
      const unfilteredSelectedNodes = selectedNodes.filter((sn) => {
        // cross reference the selected and unfiltered nodes
        // users don't expect nodes that are filtered out to remain in their selection
        const idx = findIndex(unfilteredNodes, (un) => un.id === sn.id);
        return idx > -1;
      });

      const toggledItems = this.getToggledItems(unfilteredSelectedNodes);
      this.setState(
        {
          selectedItems: unfilteredSelectedNodes,
          previousSelectionType: hasGroupNodes || this.state.previousSelectionType === 'group' ? 'group' : 'single',
        },
        () => {
          // Must call this in order to refresh the floating filters
          // other methods of forcing floating filter refresh didn't work,
          // including filterInstance.refresh() and gridApi.destroyFilter()
          this.gridApi.refreshHeader();

          if (this.props.onItemClicked) {
            if (hasGroupNodes || this.state.previousSelectionType === 'group') {
              // filter out group row and send only data for each row
              const toggledItemsData = toggledItems.filter((ci) => !isNil(ci.data)).map((ci) => ci.data);
              this.props.onItemClicked(toggledItemsData);
            } else {
              const toggledItem = toggledItems[0];
              this.props.onItemClicked(toggledItem?.data);
            }
          }
        }
      );
    },
    5,
    { trailing: true }
  );

  render() {
    const { configLoaded, dataLoaded } = this.props;

    if (!configLoaded) {
      return <Overlay type="loading" visible={true} />;
    }

    // Added to prevent valueGetters from other tree col defs to be added (intereferes with valueFormatter)
    const gridOptions: DataGridProps = {
      data: this.props.data,
      isPrintMode: false,
      columnDefs: this.state.columnDefs,
      className: gridListPairStyle,
      loaded: dataLoaded,
      scrollTo: this.props.gridScrollTo,
      singleClickEdit: true,
      refreshColumnsOnPropsChange: true,
      rowClassRules: {
        'header-row': (params: CellClassParams) => !isNil(params.data) && !isNil(params.data[GroupHeaderKey]),
      },
      onGridReady: (params: GridReadyEvent) => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.props.handleGridReady(params);
      },
      onCellClicked: this.props.onCellClicked,
      onCellEditingStarted: (event: CellEditingStartedEvent) => {
        // TODO: store invalid dataIndices in state for quick access within method, can loop and clear each one
        // clear invalid data here so invalid styles are cleared
        resetAsyncValidationData(event);
      },
      extraAgGridProps: {
        floatingFiltersHeight: this.enableFloatingFiltersRow() ? 45 : undefined,
        enableGroupEdit: true,
        autoGroupColumnDef: {
          headerName: 'Group',
          pinned: true,
        },
        groupDefaultExpanded: 10, //arbitrary number to ensure all groups expanded
        getRowId: (params) => {
          // we have a special group style with explicit id
          // this happens in `groupedToAgFlatTree`
          if (params.data.group != null) {
            return params.data[this.props.leafIdProp] ? params.data[this.props.leafIdProp] : params.data.id;
          } else {
            if (params.data[this.props.leafIdProp]) {
              return params.data[this.props.leafIdProp];
            }
            return params.data.id;
          }
        },
        getRowHeight: (params: { node: any }) => {
          return getGridRowHeight(params.node, this.props.gridRowHeight, this.props.groupRowHeight);
        },
        getGroupRowAgg: this.groupRowAggNodes,
        suppressColumnVirtualisation: true, // styling gets broken when this is on,
        suppressNoRowsOverlay: false,
        noRowsOverlayComponent: GridNoRowsOverlay,
        noRowsOverlayComponentParams: {
          noRowsOverlayConfig: this.props.noRowsOverlayConfig,
        },
        onCellEditingStopped: async (event: CellEditingStoppedEvent) => {
          const { colDef, node } = event;
          const { columnDefs, dataApi, syncGridData } = this.props;
          const field = colDef.field;
          const fieldConfig = columnDefs.find((item) => item.dataIndex === field);

          // Redecoration is being handled here because certain custom editors handle their own updates internally,
          // but because the updated field internal to the modal may be different than the field of the triggered column for edit
          // the value returned from `getValue` will be the same which will not trigger the `onCellValueChanged` redecorate logic.

          if (
            fieldConfig &&
            EDITORS_TO_IGNORE_CHANGE_DETECTION.indexOf(fieldConfig.inputType as string) >= 0 &&
            dataApi.params
          ) {
            const dependents = fieldConfig?.dependents || [];
            const defnId = !dataApi.isListData ? dataApi.params.defnId : dataApi.defnId;

            if (defnId && field) {
              updateNodeAsyncState(node, [...dependents, field], AsyncCellState.Redecorating);
              refreshGridCells(this.gridApi, [node], [...dependents, field]);
              const redecoratedData = await this.redecorateRows([node.data], [node.data], defnId);
              this.gridApi.applyTransaction({ update: redecoratedData });
              syncGridData(redecoratedData);
              updateNodeAsyncState(node, [...dependents, field], AsyncCellState.Idle);
              refreshGridCells(this.gridApi, [node], [...dependents, field]);
            }
          }
        },
        onCellValueChanged: async (event: CellValueChangedEvent) => {
          const { colDef, data: eventData, node, column } = event;
          let { value } = event;

          const { columnDefs, dependentCalcs, activeStyleColor } = this.props;
          const field = colDef.field;
          const fieldConfig = columnDefs.find((item) => item.dataIndex === field);
          const dependents = fieldConfig?.dependents || [];
          const observers = this.observers[field || ''];
          const scopedData = { ...eventData };

          // Group node updates now handled directly in the valueSetter
          if (isGroupNode(node)) {
            return;
          }

          // NOTE: it's going to get a bit worse before it gets better here. I'm sorry.
          // This *should* completely replace all that chiz below once we have the single target
          // endpoint and the willpower. - Mark :/
          if (this.columnUsesGenericUpdate(colDef)) {
            await this.submitGranularUpdate(value, [node], colDef);
          } else if (
            fieldConfig &&
            EDITORS_TO_IGNORE_CHANGE_DETECTION.indexOf(fieldConfig.inputType as string) >= 0 &&
            this.props.dataApi.params
          ) {
            // nothing to do, redecorate already handled in cellEditingStopped event handler
          } else {
            // No backend updates here:
            if (fieldConfig && fieldConfig.inputType === 'textValidatorAsync' && value === PENDING_VALIDATION_VALUE) {
              return; // skip posting unvalidated values
            }

            if (column.getColId() === 'asyncstate') {
              return; // skip generic updates for this column
            }

            if (this.props.updateCoordinateMap != null) {
              // FIXME: This is once again a "worse before it gets better" sort of deal
              // We are only using new endpoint where config is set.
              // We don't need observers as expected to be handled by redecorate
              await this.submitGranularUpdate(value, [node], colDef);
            } else if (fieldConfig && fieldConfig.dataApiLifecycle && !fieldConfig.lifecycleConfig && field) {
              // Support posting lifecycle attributes from the grid
              if (isNil(value)) return;
              const trimmedField = field.indexOf(':') != -1 ? field.split(':')[1] : field;
              const finalData = {
                product: node.id,
                attributes: {
                  [trimmedField]: value,
                },
              };

              await Axios.post(fieldConfig.dataApiLifecycle.url, finalData, {
                params: {
                  appName: 'Assortment',
                },
              });
              // return;
            } else if (fieldConfig && field && updateWithClientHandler(field, this.props.clientActionHandlers)) {
              const strippedField = field.replace('attribute:', '').replace(':id', '').replace(':name', '');
              await updateLifecycleParams(activeStyleColor, { [strippedField]: value });
              // at some point may need to allow observed logic to run before returning but for now it's okay
              // return;
            } else if (field) {
              if (fieldConfig && fieldConfig.valueType === 'number') {
                value = parseFloat(value);
              } else if (fieldConfig && fieldConfig.inputType === 'integer') {
                value = String(value); // server expects a string value
              }

              // using config value to determine if posting style or stylecolor attribute update
              const strippedField = replaceExtraProps(field);
              const postObject = this.getPostObject(strippedField, value, eventData);

              // format array types to string lists where necessary, don't want to format asCsv data here
              if (!isNil(fieldConfig) && fieldConfig.postArrayAsString) {
                const arrayAsString = `{${postObject[strippedField].join(',')}}`;
                postObject[strippedField] = arrayAsString;
              }

              // send hierarchy update to complete process
              if (field.indexOf('member:') >= 0) {
                const hierarchyData = Object.keys(scopedData)
                  .filter((key) => key.match(/member:.*:id/) != null && [STYLE_ID, STYLE_COLOR_ID].indexOf(key) < 0)
                  .map((key) => scopedData[key]);
                const hierarchyPostObj = this.getPostObject('', '', scopedData, hierarchyData);
                await updateStyleItem(hierarchyPostObj);
              }

              // Look for dependentCalcs
              const calcKeys = Object.keys(dependentCalcs);
              if (calcKeys.length > 0 && scopedData) {
                calcKeys.forEach((key) => {
                  let updateCalculation = false;
                  const calcObj = dependentCalcs[key];
                  const params = calcObj.params;
                  const allowNonNumber = calcObj.allowNonNumber;

                  if (params) {
                    for (const p in params) {
                      if (params[p] === field || params[p] === field.replace(':id', ':name')) {
                        updateCalculation = true;
                      }
                    }
                  }
                  if (updateCalculation) {
                    const getDataFromKey = (key: string) => {
                      return {
                        rowNodeFound: true,
                        data: scopedData[key],
                      };
                    };
                    const newValue = executeCalculation(this.math, calcObj, getDataFromKey, allowNonNumber);
                    // update postObject
                    postObject[strippedField] = newValue;
                  }
                });
              }

              updateNodeAsyncState(node, [...dependents, field], AsyncCellState.Processing);
              refreshGridCells(this.gridApi, [node], [...dependents, field]);
              await updateStyleItem(postObject);
              updateNodeAsyncState(node, [...dependents, field], AsyncCellState.Idle);
              refreshGridCells(this.gridApi, [node], [...dependents, field]);
            }
            if (!isNil(this.gridApi) && field && isObservedProp(this.observers, field)) {
              // update observed prop first
              observers.forEach(async (observer) => {
                const processedDataApi = processApiParams(observer.dataApi, scopedData);
                const dataUrl = getUrl(processedDataApi);
                const resp = await Axios.get(dataUrl);
                let respData = resp.data && resp.data.data ? resp.data.data : null;
                if (!isNil(respData) && !isArray(respData)) {
                  respData = getDependentsFromResp(respData);
                }

                const memberMatched = observer.dataIndex.match(/member:([a-z]*):[a-z]*/);
                const isMemberUpdate = !isNil(memberMatched);
                let updatedValue = isMemberUpdate ? { label: '', value: '' } : '';

                if (!isNil(respData) && !isEmpty(respData[0])) {
                  // Get current value of field
                  const current = node.data[observer.dataIndex];
                  const result = respData.filter((option: { name: any }) => option.name === current);

                  // Only update shown value if data is not in respData
                  if (!respData.includes(current) && result.length <= 0) {
                    // select first item as selection if options are available
                    updatedValue = isMemberUpdate
                      ? {
                          label: respData[0].name,
                          value: respData[0].id,
                        }
                      : respData[0]; // assuming others always use dependent endpoint here...
                  }
                  // Otherwise, keep current value
                  else {
                    if (result.length > 0) {
                      updatedValue = {
                        label: result[0].name,
                        value: result[0].id,
                      };
                    } else {
                      updatedValue = current;
                    }
                  }
                }

                // setting the observer prop's new value will retrigger onCellValueChanged which will handle posting
                node.setDataValue(observer.dataIndex, updatedValue);
                refreshGridCells(this.gridApi, [node], [event.columnApi.getColumn(observer.dataIndex) || '']);
              });
            }
          }
          // we have to refresh to keep the column header in sync with the state of column children
          if (fieldConfig && fieldConfig.inputType === 'checkbox') {
            this.gridApi.refreshHeader();
          }

          // update observed and dependent props, then post hierarchy data as well
          // FIXME: We're handling a lot of setter logic in here that's probably duplicated in the separate
          // setValue. We're also doing some...special...things w/r/t how different endpoints schema for
          // the dropdowns are setup.
        },
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        isRowSelectable: this.props.isRowSelectable,
        onSelectionChanged: this.handleCheck,
        onRowSelected: (event) => {
          const { node } = event;
          if (node.childrenAfterFilter != null && node.childrenAfterFilter.length > 0) {
            node.childrenAfterFilter.forEach((childNode) => {
              childNode.setSelected(!!node.isSelected());
            });
          }
          if (!isNil(this.props.onRowSelected)) {
            this.props.onRowSelected(event);
          }
        },
        // Cell clipboard processing function for copy/paste
        processCellFromClipboard: (params) => {
          const colDef = params.column.getColDef() as any;
          const inputType = colDef.refData.inputType;

          // If input type is not number, accept the paste as is
          if (inputType !== 'integer') {
            return params.value;
          }

          // If input type is number, process the value
          if (inputType === 'integer') {
            // Strip non-digit and non-period characters from the pasted value
            const cleanedValue = params.value.replace(/[^0-9.]/g, '');
            let parsedValue = parseFloat(cleanedValue);

            // If the parsed value is not a number, return null
            if (isNaN(parsedValue)) {
              return null;
            }

            // Check if the original value ends with a percent symbol or percent renderer (percents can copy two different ways)
            if (params.value.trim().endsWith('%')) {
              parsedValue = parsedValue / 100;
            }

            return parsedValue;
          }

          // return the original value if no conditions are met
          return params.value;
        },
      },
    };

    return (
      <div className={gridContainerStyle}>
        <ExtendedDataGrid
          {...gridOptions}
          frameworkComponents={frameworkComponents}
          nonFrameworkComponents={nonFrameworkComponents}
        />
      </div>
    );
  }
}
export default EditableGrid;
