import React, { Component } from 'react';
import { isNil, pick } from 'lodash';
import { Tab, Tabs } from '@material-ui/core';

import {
  WorklistTabsProps,
  WorklistViewContainerProps,
  WorklistViewContainerState,
} from 'src/pages/Worklist/WorklistViewContainer.types';
import StylePreview from 'src/components/StylePreview/StylePreview';
import { TabbedPricingGrid } from 'src/pages/AssortmentBuild/Pricing/TabbedPricingGrid';
import { TabbedFlowSheetGrid } from 'src/pages/AssortmentBuild/FlowSheet/TabbedFlowSheetGrid';
import { TabbedNestedOvertimeGrid } from 'src/pages/AssortmentBuild/OvertimeView/NestedOvertime/TabbedNestedOvertime';
import { TabbedStyleOvertimeGrid } from 'src/pages/AssortmentBuild/OvertimeView/StyleByLevel/TabbedStyleOvertime';
import stylesWorklist from 'src/pages/Worklist/Worklist.styles';
import { WrappedVisualize } from 'src/components/Visualize/Visualize.container';
import { externalGridSearchFields } from 'src/utils/Domain/Constants';
import { getFourthPathUrl } from 'src/pages/NavigationShell/navigationUtils';
import { connect } from 'react-redux';
import { AppState } from 'src/store';
import CollectionView from 'src/pages/Hindsighting/StyleColorReview/CollectionView/CollectionView.container';
import GridViewContainer from 'src/pages/Hindsighting/StyleColorReview/GridView/GridView.container';
import FlowTypeContainer from 'src/pages/Hindsighting/StyleColorReview/FlowType/FlowType.container';
import SummaryViewContainer from 'src/pages/Hindsighting/StyleColorReview/SummaryView/SummaryView.container';
import Subheader from 'src/components/Subheader/Subheader.container';
import NestedViewContainer from 'src/pages/Hindsighting/HistoryGrid/NestedView/NestedView.container';
import EnhancedOvertime from 'src/components/EnhancedOvertime/EnhancedOvertime';
import { ConfDefnComponentType } from 'src/services/configuration/codecs/confdefnComponents';
import { LegacyWorklistComponentType, WorklistTab } from 'src/services/configuration/codecs/Worklist';
import QuickTrends from 'src/pages/Hindsighting/MacroTrends/QuickTrends/QuickTrends';
import { Link } from 'react-router-dom';
import ReceiptGrid from 'src/components/ReceiptGrid/ReceiptGrid.container';
import { SubheaderOwnProps } from 'src/components/Subheader/Subheader.types';
import ConfigurableGrid from 'src/components/ConfigurableGrid/ConfigurableGrid.container';
import GeoTrends from '../Hindsighting/MacroTrends/GeoTrends/GeoTrends.container';
import CategorySummary from '../Hindsighting/CategoryRecap/CategorySummary/CategorySummary.container';
import NestedAttributeContainer from '../Hindsighting/AggregateViews/NestedAttribute/NestedAttribute.container';
import { ListViewable } from 'src/common-ui/components/CompanionListView/CompanionListView';

function isOverTimeComponent(selectedTabComponentType: ConfDefnComponentType | LegacyWorklistComponentType) {
  return [
    ConfDefnComponentType.nestedStyleOverTime,
    LegacyWorklistComponentType.nestedStyleOverTime,
    ConfDefnComponentType.nestedOvertime,
    LegacyWorklistComponentType.nestedOvertime,
  ].some((componentType) => componentType === selectedTabComponentType);
}

const WorklistTabs = ({ tabsConfig, selectedIndex, getUrl }: WorklistTabsProps) => {
  return (
    <div className={stylesWorklist.tabsContainer}>
      <Tabs value={selectedIndex} variant={'fullWidth'}>
        {tabsConfig.map(({ title }, index) => {
          const url = getUrl(tabsConfig[index]);
          if (!url.pathname) {
            return <Tab key={`${title}-${index}`} label={title} />;
          }
          return <Tab key={`${title}-${index}`} to={url} component={Link} label={title} />;
        })}
      </Tabs>
    </div>
  );
};

class WorklistViewContainer extends Component<WorklistViewContainerProps, WorklistViewContainerState> {
  constructor(props: WorklistViewContainerProps) {
    super(props);
  }

  getUrl = (tab: WorklistTab) => {
    let pathname: string | undefined = getFourthPathUrl(this.props.activePage, tab.pathSlot);
    if (pathname == location.hash.substring(1, location.hash.length)) {
      pathname = undefined;
    }
    return { pathname };
  };

  getTabFromSubPage = () => {
    const maybeTabIndex = this.props.tabsConfig.findIndex((x) => x.pathSlot == this.props.activeSubPage);
    return maybeTabIndex > -1 ? maybeTabIndex : this.props.defaultTabIndex;
  };

  createTabContent = (selectedCompanionItem: ListViewable) => {
    const { tabsConfig, subheaderSlice, styles, selectedListDataItem } = this.props;
    const selectedTabConfig = tabsConfig[this.getTabFromSubPage()];

    const { id, name, stars, imageUri, title, style } = selectedCompanionItem;
    const stylePreviewData = {
      id,
      description: name,
      starRating: stars || 0,
      imgUri: imageUri || '',
      title,
      style,
    };

    switch (selectedTabConfig.componentType) {
      case ConfDefnComponentType.visualize:
      case LegacyWorklistComponentType.visualize:
        return (
          <WrappedVisualize
            viewDefns={selectedTabConfig.viewDefns}
            selectedItemPreviewData={stylePreviewData}
            subheaderSlice={subheaderSlice}
            styles={styles}
            dontFilterSwatches={true}
          />
        );
      case ConfDefnComponentType.pricing:
      case LegacyWorklistComponentType.pricing:
        // 52px is the height of the tab currently. Need to wrap like this as
        // makeScopeAndFilterSensitive wraps a 100% height div around the component
        // making the scrollbar show up before you could change the height in the component to not overflow
        return (
          <div style={{ height: `calc(100% - ${stylesWorklist.WORKLIST_TAB_HEIGHT}px)`, width: '100%' }}>
            <TabbedPricingGrid
              selectedItemId={id}
              viewDefns={selectedTabConfig.viewDefns}
              showFlowStatus={selectedTabConfig.showFlowStatus}
              showUndoBtn={this.props.showUndoBtn}
              key={`${selectedTabConfig.pathSlot}`}
            />
          </div>
        );
      case ConfDefnComponentType.flowSheet:
      case LegacyWorklistComponentType.flowsheet:
        return (
          <div style={{ height: `calc(100% - ${stylesWorklist.WORKLIST_TAB_HEIGHT}px)`, width: '100%' }}>
            <TabbedFlowSheetGrid
              styles={this.props.styles}
              selectedItemId={id}
              viewDefns={selectedTabConfig.viewDefns}
              massEditModel={selectedTabConfig.massEditModel}
              showFlowStatus={selectedTabConfig.showFlowStatus}
              dataApi={selectedTabConfig.componentProps?.dataApi}
              showUndoBtn={selectedTabConfig.componentProps?.showUndoBtn}
              key={`${selectedTabConfig.pathSlot}`}
            />
          </div>
        );
      case ConfDefnComponentType.nestedStyleOverTime:
      case LegacyWorklistComponentType.nestedStyleOverTime:
        return (
          <div style={{ height: '100%', width: '100%' }}>
            <TabbedStyleOvertimeGrid
              selectedItemId={id}
              viewDefns={selectedTabConfig.componentProps.defns.view}
              key={`${selectedTabConfig.pathSlot}`}
              // @ts-ignore
              dataApi={selectedTabConfig.componentProps.dataApi}
            />
          </div>
        );
      case ConfDefnComponentType.nestedOvertime:
      case LegacyWorklistComponentType.nestedOvertime:
        return (
          <div style={{ height: `calc(100% - ${stylesWorklist.WORKLIST_TAB_HEIGHT}px)`, width: '100%' }}>
            <TabbedNestedOvertimeGrid
              selectedItemId={id}
              viewDefns={selectedTabConfig.componentProps.defns.view}
              key={`${selectedTabConfig.pathSlot}`}
              // @ts-ignore
              dataApi={selectedTabConfig.componentProps.dataApi}
            />
          </div>
        );
      case ConfDefnComponentType.styleEdit:
      case LegacyWorklistComponentType.styleEdit:
        const { viewDefns, pathSlot } = selectedTabConfig;
        const subheaderProps: SubheaderOwnProps = {
          title: '',
          sortByDefn: !isNil(viewDefns[1]) ? viewDefns[1] : 'AssortmentBuildStyleEditSortBy',
          showAlternateFlowStatus: true,
          showAlternateSearch: true,
        };
        return (
          <React.Fragment>
            <Subheader {...subheaderProps} />
            <StylePreview
              adornments={[]}
              filters={pick(subheaderSlice, 'altSearch', 'altFlowStatus', 'sortBy')}
              searchKeys={externalGridSearchFields}
              previewData={stylePreviewData}
              renderSections={true}
              sectionsViewDefns={viewDefns}
              onStyleItemEdit={this.handleStyleItemUpdate}
              onRefreshView={this.handleRefreshView}
              key={`${pathSlot}`}
            />
          </React.Fragment>
        );
      case ConfDefnComponentType.collectionView:
      case LegacyWorklistComponentType.collectionView:
        return (
          <CollectionView
            {...selectedTabConfig.componentProps}
            topMembers={id}
            hideTitle={selectedTabConfig.componentProps.hideTitle}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.gridView:
      case LegacyWorklistComponentType.gridView:
        return (
          // @ts-ignore
          <GridViewContainer
            {...selectedTabConfig.componentProps}
            topMembers={id}
            hideTitle={selectedTabConfig.componentProps.hideTitle}
            hideCompanion={true}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.configurableGrid:
        return (
          <ConfigurableGrid
            {...selectedTabConfig.componentProps}
            hideCompanion={true}
            topMembers={id}
            topMemberObj={selectedListDataItem}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.receiptGrid:
        return (
          <ReceiptGrid
            {...selectedTabConfig.componentProps}
            hideCompanion={true}
            topMembers={id}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.flowType:
      case LegacyWorklistComponentType.flowType:
        return (
          <FlowTypeContainer
            {...selectedTabConfig.componentProps}
            topMembers={id}
            hideTitle={selectedTabConfig.componentProps.hideTitle}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.summaryView:
      case LegacyWorklistComponentType.summaryView:
        return (
          <SummaryViewContainer
            {...selectedTabConfig.componentProps}
            topMembers={id}
            hideTitle={selectedTabConfig.componentProps.hideTitle}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.quickTrends:
        return (
          <QuickTrends {...selectedTabConfig.componentProps} topMembers={id} key={`${selectedTabConfig.pathSlot}`} />
        );
      case ConfDefnComponentType.nestedView:
      case LegacyWorklistComponentType.nestedView:
        return (
          <NestedViewContainer
            {...selectedTabConfig.componentProps}
            topMembers={id}
            hideTitle={selectedTabConfig.componentProps.hideTitle}
            hideCompanion={true}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.enhancedOvertime:
        return (
          <EnhancedOvertime
            {...selectedTabConfig.componentProps}
            topMembers={selectedCompanionItem.id}
            hideTitle={selectedTabConfig.componentProps.hideTitle}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.geoTrends:
        return (
          <GeoTrends
            {...selectedTabConfig.componentProps}
            topMembers={selectedCompanionItem.id}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.categorySummary:
        return (
          <CategorySummary
            {...selectedTabConfig.componentProps}
            topMembers={id}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      case ConfDefnComponentType.nestedAttribute:
        return (
          <NestedAttributeContainer
            {...selectedTabConfig.componentProps}
            topMembers={id}
            key={`${selectedTabConfig.pathSlot}`}
          />
        );
      default:
        return <div>Invalid Component Type has been configured</div>;
    }
  };

  handleRefreshView = () => {
    this.props.onViewRefresh();
  };

  handleStyleItemUpdate = (styleId: string, propToUpdate: string, propValue: string) => {
    this.props.onStyleItemUpdate(styleId, propToUpdate, propValue);
  };

  render() {
    const { tabsConfig, isPrintMode, selectedCompanionItem } = this.props;

    const selectedIndex = this.getTabFromSubPage();
    const selectedTabConfig = tabsConfig[selectedIndex];
    const hideOverflow = isOverTimeComponent(selectedTabConfig.componentType);
    const overflow = isPrintMode ? 'visible' : hideOverflow ? 'hidden' : 'auto';
    return (
      <div className={stylesWorklist.worklistViewContainer(isPrintMode)}>
        {tabsConfig.length >= 2 && (
          <WorklistTabs
            tabsConfig={tabsConfig}
            selectedIndex={this.getTabFromSubPage()}
            getUrl={(tab: WorklistTab) => this.getUrl(tab)}
          />
        )}
        <div
          style={{
            height: tabsConfig.length <= 1 ? '100%' : 'calc(100% - 48px)',
            overflowY: overflow,
            position: 'relative',
          }}
        >
          {this.props.styles && this.createTabContent(selectedCompanionItem)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return { activeSubPage: state.perspective.activeSubPage, activePage: state.perspective.activePage };
};

export default connect(mapStateToProps)(WorklistViewContainer);
